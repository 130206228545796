import PropTypes from 'prop-types';
import React from 'react';

import LinkedConversationsModule from '../LinkedConversationsModule';
import LinkedVsitsModule from '../LinkedVsitsModule';

const CommonModules = (props) => {
  const hasSelectedTemplate = !!props.templates.find((template) => template.isSelected);
  if (!hasSelectedTemplate) return null;

  return (
    <>
      <LinkedConversationsModule {...props} />
      <LinkedVsitsModule {...props} />
    </>
  );
};

CommonModules.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object),
};

export default CommonModules;
