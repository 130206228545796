// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CGMOrderPopup-module__buttonsContainer___VWA1v {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 19px 30px;
  gap: 16px;
  border-top: 1px solid rgba(91, 91, 91, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/popups/CGMOrderPopup/CGMOrderPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,SAAA;EACA,2CAAA;AACF","sourcesContent":[".buttonsContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n  padding: 19px 30px;\n  gap: 16px;\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `CGMOrderPopup-module__buttonsContainer___VWA1v`
};
module.exports = ___CSS_LOADER_EXPORT___;
