// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Badge-module__badge___Ok4TB {
  min-width: 24px;
  padding: 1px 6px;
  border-radius: 14px;
  letter-spacing: 0.5px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./../common/src/components/base/Badge/Badge.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;EACA,sBAAA;AACF","sourcesContent":[".badge {\n  min-width: 24px;\n  padding: 1px 6px;\n  border-radius: 14px;\n  letter-spacing: 0.5px;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `Badge-module__badge___Ok4TB`
};
module.exports = ___CSS_LOADER_EXPORT___;
