// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SmartGoalsList-module__empty___GUyBZ {
  padding: 14px 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/SmartGoals/SmartGoalsList/SmartGoalsList.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".empty {\n  padding: 14px 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": `SmartGoalsList-module__empty___GUyBZ`
};
module.exports = ___CSS_LOADER_EXPORT___;
