import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  InteractionStatus,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';

import { closePatientDetails, getNoteData } from 'src/features/tabsState';
import { getTimer } from 'src/features/timeTracker';
import useCustomSelector from 'src/hooks/useCustomSelector';

const useSaveNote = (patientId, note, isEmbedded) => {
  const dispatch = useDispatch();

  const prevNote = useRef();
  const prevTimer = useRef(0);
  const prevNoteData = useRef({});
  const latestNoteData = useRef({});
  const latestTimer = useRef(0);

  const timer = useCustomSelector((state) => getTimer(state, patientId));
  const noteData = useCustomSelector((state) => getNoteData(state, patientId));

  useEffect(() => {
    prevNote.current = note;
    prevTimer.current = timer;
    prevNoteData.current = noteData;

    if (timer) {
      latestTimer.current = timer;
    }

    if (!_.isEmpty(noteData)) {
      latestNoteData.current = noteData;
    }
  }, [note, timer, noteData]);

  useEffect(
    () => () => {
      if (
        !isEmbedded ||
        !prevNote.current ||
        prevNote.current.status === InteractionStatus.Finalized
      )
        return;

      // Pause note draft when user closes patient tab
      if (_.isEmpty(prevNoteData.current)) {
        dispatch(
          updatePatientDraftApiRequest(
            prevNote.current.id,
            {
              ...latestNoteData.current,
              duration: Math.max(prevNote.current.duration, latestTimer.current),
              status: InteractionStatus.Paused,
            },
            {},
          ),
        );
        dispatch(closePatientDetails(patientId));

        return;
      }

      // Save note draft when user leaves Note page
      dispatch(
        updatePatientDraftApiRequest(
          prevNote.current.id,
          {
            ...prevNoteData.current,
            duration: Math.max(prevNote.current.duration, prevTimer.current),
          },
          {},
        ),
      );
    },
    [],
  );
};

export default useSaveNote;
