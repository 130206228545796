import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';

import { getRoleConfig, isViewOnly } from 'common-src/features/auth';
import { getIsClientsCached } from 'common-src/features/cache';
import { apiRequest } from 'common-src/features/rest';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { getPatientRestRequest } from 'common-src/models/Patient';
import { getPatientCoveragesRequest } from 'common-src/models/PatientCoverage';
import { getCoverageHierarchyRequest } from 'common-src/models/PatientCoverageHierarchy';
import {
  getLatestPatientInteractionRequest,
  getPatientInteractionsApiRequest,
  InteractionStatus,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';
import {
  getPatientLabReadingsRequest,
  LabResultsTypeIds,
} from 'common-src/models/PatientLabReading';
import { getPatientMedicalProblemsRequest } from 'common-src/models/PatientMedicalProblem';
import { MedicationStatus } from 'common-src/models/PatientMedication';
import { getPatientsPhoneNumbersRequest } from 'common-src/models/PatientPhoneNumber';
import { getPatientSdohRequest } from 'common-src/models/PatientSdoh';
import { ActivePatientTaskStatuses, PatientTaskStatus } from 'common-src/models/PatientTask';
import { getPatientTasksRequest } from 'common-src/models/PatientTask/actions';
import { getPatientVitalsRequest } from 'common-src/models/PatientVital';
import { getSdohCodesRequest } from 'common-src/models/SdohCode';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { RequestHandlerScreen } from 'src/components/base';
import { MainFooter } from 'src/components/navigation';
import MasterDetailLayout from 'src/components/navigation/MasterDetailLayout';
import { DetailSection } from 'src/components/navigation/MasterDetailLayout/sections';
import AvatarPlaceholder from 'src/components/widgets/AvatarPlaceholder';
import { CLIENT_ROOT } from 'src/constants/paths';
import {
  getNoteData,
  getPatientNote,
  getTabByPath,
  PatientTabs,
  savePatientDetailsTabInfo,
  savePatientDocumentationTabInfo,
  savePatientMainTab,
} from 'src/features/tabsState';
import { getTimer } from 'src/features/timeTracker';
import CGMOrdersFragment from 'src/fragments/CGMOrdersFragment';
import DevicesFragment from 'src/fragments/DevicesFragment';
import DocumentationFragment from 'src/fragments/DocumentationFragment';
import TimelineFragment from 'src/fragments/TimelineFragment';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import useInterval from 'src/hooks/useInterval';
import usePatientStatusBanner from 'src/hooks/usePatientStatusBanner';
import { DocumentComponent } from 'src/pages/DocumentationIndex/forms';

import CurieAISidebar from './CurieAISidebar';
import {
  Allergies,
  Biometrics,
  Conditions,
  Contacts,
  Encounters,
  Exemptions,
  Family,
  KnownPlans,
  Labs,
  Medications,
  Orders,
  PayorPriority,
  PreAuths,
  Profile,
  ScheduledCalls,
  Sdoh,
  SmartGoals,
  Social,
  Supplements,
  Surgeries,
  Tasks,
  Team,
  Vitals,
} from './fragments';
import styles from './PatientDetails.module.scss';
import PatientDetailsNavigation from './PatientDetailsNavigation';
import PatientDetailsSidebar from './PatientDetailsSidebar';

const PatientDetails = () => {
  const dispatch = useDispatch();

  const [routesWithActionItems, setRoutesWithActionItems] = useState([]);
  const [medsNeededAction, setMedsNeededAction] = useState(0);
  const [isRequesting, setIsRequesting] = useState(false);

  const draftData = useRef({});

  const params = useParams();
  const patientId = Number(params.patientId);
  const fragment = params['*'];

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const permissions = useCustomSelector((state) => getRoleConfig(state)?.patientChart) || {};
  const sections = permissions?.sections || {};
  const actions = permissions?.actions || {};
  const isClientsCached = useCustomSelector((state) => getIsClientsCached(state));
  const noteId = useCustomSelector((state) => getPatientNote(state, patientId));
  const timer = useCustomSelector((state) => getTimer(state, patientId));
  const noteData = useCustomSelector((state) => getNoteData(state, patientId));

  const { showBanner, statusMessage } = usePatientStatusBanner(patientId);
  useDocumentTitle(patient ? patient.getName() : '');

  const statusTextClasses = [
    styles.statusText,
    'vertically-centered',
    'horizontally-centered',
    'font-s-14',
  ];
  if (patient?.holdStartsAt) {
    statusTextClasses.push(styles.yellow);
  }

  const updateRoutesWithActionItems = (data, modelName, routeName) => {
    setRoutesWithActionItems((prev) =>
      _.isEmpty(data?.[modelName])
        ? prev.filter((route) => route !== routeName)
        : _.uniq([...prev, routeName]),
    );
  };

  const getActionItemsMedications = () => {
    apiRequest({
      endpoint: 'patientMedications',
      queryParams: {
        patientId,
        status: [MedicationStatus.NeedsReview, MedicationStatus.NeedsRefill],
      },
    })
      .then((res) => res.json())
      .then((data) => {
        updateRoutesWithActionItems(data, 'PatientMedication', 'medications');
        setMedsNeededAction(data?.PatientMedication?.length || 0);
      });
  };

  const getActionItemsTasks = () => {
    apiRequest({
      endpoint: 'patientTasks',
      queryParams: {
        patientId,
        status: [PatientTaskStatus.New.value],
      },
    })
      .then((res) => res.json())
      .then((data) => {
        updateRoutesWithActionItems(data, 'PatientTask', 'tasks');
      });
  };

  useInterval(() => {
    dispatch(
      restRequestMultiple({
        restRequests: [
          getPatientRestRequest(patientId),
          getPatientsPhoneNumbersRequest({ patientId }),
          getPatientInteractionsApiRequest({
            patientId,
            skip: 0,
            limit: 1,
            sort_order: 'desc',
            sort_by: 'updated_at',
          }),
          getPatientMedicalProblemsRequest({ patientId, skip: 0, limit: 5, sort_order: 'desc' }),
          getPatientVitalsRequest({ patientId, skip: 0, limit: 1, sort_order: 'desc' }),
          getPatientSdohRequest({ patientId, skip: 0, limit: 10, sort_order: 'desc' }),
          getPatientTasksRequest({
            patientId,
            curie: true,
            skip: 0,
            limit: 10,
            sort_order: 'desc',
          }),
        ],
      }),
    );

    getActionItemsMedications();
    getActionItemsTasks();
  });

  useEffect(() => {
    if (!isClientsCached) return;

    const restRequests = [
      getPatientRestRequest(patientId),
      getPatientInteractionsApiRequest({ patientId, isDraft: true }),
      getLatestPatientInteractionRequest(patientId),
      getPatientMedicalProblemsRequest({ patientId }),
      getPatientsPhoneNumbersRequest({ patientId }),
      getPatientCoveragesRequest(
        { patientId },
        { successBlock: () => dispatch(getCoverageHierarchyRequest({ patientId })) },
      ),
      getPatientVitalsRequest({ patientId, skip: 0, limit: 1, sort_order: 'desc' }),
      getSdohCodesRequest(),
      getPatientSdohRequest({ patientId }),
      getPatientLabReadingsRequest({ patientId, typeIds: LabResultsTypeIds }),
      getPatientTasksRequest({ patientId, curie: true, status: ActivePatientTaskStatuses }),
    ];

    setIsRequesting(true);
    dispatch(
      restRequestMultiple({
        restRequests,
        successBlock: () => setIsRequesting(false),
        errorBlock: () => setIsRequesting(false),
      }),
    );

    dispatch(savePatientDocumentationTabInfo(patientId, {}));
    getActionItemsMedications();
    getActionItemsTasks();
  }, [patientId, isClientsCached]);

  useEffect(() => {
    const path =
      fragment === `${PatientTabs.Note.text}`.toLocaleLowerCase()
        ? `${CLIENT_ROOT}/patients/details/${patientId}/${fragment}/${noteId}/edit`
        : `${CLIENT_ROOT}/patients/details/${patientId}/${fragment}`;

    dispatch(savePatientDetailsTabInfo(patientId, path));
    dispatch(savePatientMainTab(patientId, getTabByPath(path)));
  }, [patientId, fragment, noteId]);

  useEffect(() => {
    draftData.current = {
      timer,
      noteId,
      noteData,
    };
  }, [timer, noteId, noteData]);

  useEffect(
    () => () => {
      if (!draftData.current?.noteId) return;

      dispatch(
        updatePatientDraftApiRequest(
          draftData.current.noteId,
          {
            ...draftData.current.noteData,
            duration: draftData.current.timer,
            status: InteractionStatus.Paused,
          },
          {},
        ),
      );
    },
    [],
  );

  if (!isClientsCached || isRequesting || !patient) {
    return <RequestHandlerScreen isRequesting />;
  }

  return (
    <section id="patient-details-page" className={styles.section}>
      <PatientDetailsNavigation patientId={patientId} />
      {showBanner && <p className={statusTextClasses.join(' ')}>{statusMessage}</p>}
      <MasterDetailLayout classNames={[styles.master]}>
        <PatientDetailsSidebar
          patient={patient}
          routesWithActionItems={routesWithActionItems}
          fragment={fragment}
        />
        <DetailSection classNames={[styles.detailSection]}>
          <Routes>
            <Route path=":patientId?/">
              {sections.memberInformation && (
                <Route path="info" element={<Profile patient={patient} />} />
              )}
              {sections.contacts && (
                <Route path="contacts" element={<Contacts patientId={patientId} />} />
              )}
              {sections.physicianOrders && (
                <Route path="alerts" element={<Orders patient={patient} />} />
              )}
              {sections.vitalsAndLabResults && (
                <Route path="lab-results" element={<Labs patientId={patientId} />} />
              )}
              {sections.vitalsAndLabResults && (
                <Route
                  path="vitals"
                  element={
                    <Vitals
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.vitalsAndLabResults)}
                    />
                  }
                />
              )}
              {sections.smartGoals && (
                <Route
                  path="smart-goals"
                  element={
                    <SmartGoals
                      patient={patient}
                      actionsAllowed={!isViewOnly(sections.smartGoals)}
                    />
                  }
                />
              )}
              {sections.glucoseReadings && (
                <Route path="glucose-readings" element={<Biometrics patient={patient} />} />
              )}
              {sections.conversationHistory && (
                <Route
                  path="conversation-history"
                  element={<TimelineFragment patientId={patientId} />}
                />
              )}
              {sections.documentation && (
                <Route
                  path="documentation"
                  element={<DocumentationFragment patientId={patientId} />}
                />
              )}
              {sections.medicalHistory && (
                <Route
                  path="medications"
                  element={
                    <Medications
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.medicationsList)}
                    />
                  }
                />
              )}
              {sections.devicesAndOrders && (
                <Route path="devices" element={<DevicesFragment patientId={patientId} />} />
              )}
              {sections.scheduledCalls && (
                <Route path="scheduled-calls" element={<ScheduledCalls patientId={patientId} />} />
              )}

              {sections.medicationsList && (
                <Route
                  path="conditions"
                  element={
                    <Conditions
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.medicalHistory)}
                    />
                  }
                />
              )}
              {sections.allergyList && (
                <Route
                  path="allergies"
                  element={
                    <Allergies
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.allergyList)}
                    />
                  }
                />
              )}
              {sections.socialHistory && (
                <Route
                  path="social-history"
                  element={
                    <Social
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.socialHistory)}
                    />
                  }
                />
              )}
              {sections.supplementsList && (
                <Route
                  path="supplements"
                  element={
                    <Supplements
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.supplementsList)}
                    />
                  }
                />
              )}
              {sections.familyHistory && (
                <Route
                  path="family-history"
                  element={
                    <Family
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.familyHistory)}
                    />
                  }
                />
              )}
              {sections.encountersList && (
                <Route
                  path="encounters"
                  element={
                    <Encounters
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.encountersList)}
                    />
                  }
                />
              )}
              {sections.surgicalHistory && (
                <Route
                  path="surgical-history"
                  element={
                    <Surgeries
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.surgicalHistory)}
                    />
                  }
                />
              )}
              {sections.knownPlans && (
                <Route path="known-plans" element={<KnownPlans patientId={patientId} />} />
              )}
              {sections.payorPriority && (
                <Route
                  path="payor-priority"
                  element={
                    <PayorPriority
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.payorPriority)}
                    />
                  }
                />
              )}
              {sections.preAuths && (
                <Route
                  path="pre-auths"
                  element={
                    <PreAuths
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.preAuths)}
                    />
                  }
                />
              )}
              {sections.exemptions && (
                <Route
                  path="exemptions"
                  element={
                    <Exemptions
                      patientId={patientId}
                      actionsAllowed={!isViewOnly(sections.exemptions)}
                    />
                  }
                />
              )}
              {sections.cgmOrders && (
                <Route path="cgmOrders" element={<CGMOrdersFragment patientId={patientId} />} />
              )}
              {actions.canAddDocumentation && (
                <Route
                  path="note/*"
                  element={
                    <DocumentComponent
                      documentationId={fragment.split('/')[1]}
                      patientId={patientId}
                      isEmbedded
                    />
                  }
                />
              )}
              {sections.team && <Route path="team" element={<Team patient={patient} />} />}
              {sections.sdohList && <Route path="sdoh" element={<Sdoh patientId={patientId} />} />}
              {sections.tasks && <Route path="tasks" element={<Tasks patientId={patientId} />} />}
              <Route index element={<AvatarPlaceholder text="Select a Section" />} />
            </Route>
          </Routes>
        </DetailSection>
        <CurieAISidebar patientId={patientId} />
      </MasterDetailLayout>
      <MainFooter patientId={patientId} medsNeededAction={medsNeededAction} />
    </section>
  );
};

export default PatientDetails;
