import { isEmailValid, isNameValid, isNPIValid } from 'common-src/utils/validationUtils';

export const validateClient = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'firstName':
      if (!value) {
        errMsg = 'First name should not be empty';
      } else if (!isNameValid(value)) {
        errMsg = 'First name is not valid';
      }
      break;
    case 'middleName':
      if (value && !isNameValid(value)) {
        errMsg = 'Middle name is not valid';
      }
      break;
    case 'lastName':
      if (!value) {
        errMsg = 'Last name should not be empty';
      } else if (!isNameValid(value)) {
        errMsg = 'Last name is not valid';
      }
      break;
    case 'email':
      if (!value) {
        errMsg = 'Email should not be empty';
      } else if (!isEmailValid(value)) {
        errMsg = 'Email is not valid';
      }
      break;
    case 'roleId':
      if (!value) {
        errMsg = 'Role should not be empty';
      }
      break;
    case 'npi':
      if (value && !isNPIValid(value)) {
        errMsg = 'NPI is not valid';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
