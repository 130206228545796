/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import React from 'react';

import { BoldedText } from 'common-src/components/elements';
import { getRoleConfig } from 'common-src/features/auth';
import { getDateWithTimeZone } from 'common-src/utils/dateUtils';

import {
  ALL_DOCUMENT_TYPES,
  DocumentationSort,
  InteractionReason,
  InteractionType,
} from './constants';

export const getSortedDocuments = (docs, areDrafts, isReversed = false) =>
  _.orderBy(
    docs,
    ({ createdAt, savedAt, serviceAt }) => {
      const lastSavedDate = savedAt || createdAt;
      const sortField = areDrafts ? lastSavedDate : serviceAt || lastSavedDate;
      return new Date(sortField);
    },
    [isReversed ? 'desc' : 'asc'],
  );

export const getFilteredDrafts = (drafts, filters, openedDraftId) => {
  let filteredDrafts = [...drafts];

  const moduleType = filters.type?.value;
  if (!!moduleType && moduleType !== ALL_DOCUMENT_TYPES.id) {
    filteredDrafts = filteredDrafts.filter((draft) => draft.moduleTypes?.includes(moduleType));
  }

  if (openedDraftId) {
    filteredDrafts = filteredDrafts.filter((draft) => draft.id !== openedDraftId);
  }

  switch (filters.sort?.value) {
    case DocumentationSort.OLDEST_FIRST: {
      filteredDrafts = getSortedDocuments(filteredDrafts, true, false);
      break;
    }
    case DocumentationSort.NEWEST_FIRST:
    default: {
      filteredDrafts = getSortedDocuments(filteredDrafts, true, true);
      break;
    }
  }

  return filteredDrafts;
};

export const getAllowedDocumentationModules = (state) => {
  const permissions = getRoleConfig(state)?.documentation;
  const res = {};

  for (const [section, modules] of Object.entries(permissions)) {
    res[section] = new Set();

    for (const [key, value] of Object.entries(modules)) {
      if (value) res[section].add(Number(key));
    }
  }

  return res;
};

export const getDocumentationNote = (note, addendums, clients) => {
  if (_.isEmpty(addendums))
    return (
      <BoldedText
        text={note || ''}
        regex={new RegExp(/(---(.*?)---)|(\*\*\*(.*?)\*\*\*)/, 'gi')}
        splitBy={'\n\n'}
      />
    );

  const getClient = (id) => clients?.find((c) => c.id === id);

  let updatedNote = note || '';
  addendums.forEach((addendum) => {
    updatedNote += `\n\n*** Addendum ${getDateWithTimeZone(
      addendum?.serviceAt || addendum?.savedAt || addendum?.createdAt,
      'MM/DD/YYYY hh:mm A z',
    )} (by ${
      addendum?.client?.getName(false, true) ||
      getClient(addendum?.clientId)?.getName(false, true) ||
      'System'
    }) *** \n${addendum.note || ''}`;
  });

  return (
    <BoldedText
      text={updatedNote}
      regex={new RegExp(/(---(.*?)---)|(\*\*\*(.*?)\*\*\*)/, 'gi')}
      splitBy={'\n\n'}
    />
  );
};

export const getType = (typeId) =>
  _.find(Object.values(InteractionType), (type) => type.id === typeId)?.label || 'Unknown type';

export const getReason = (reasonId) =>
  _.find(Object.values(InteractionReason), (reason) => reason.id === reasonId)?.label ||
  'Unknown reason';

export const parseCGMEvaluationDraftData = (draftData) => {
  if (_.isEmpty(draftData)) return {};

  return {
    ...draftData,
    gv: draftData.gv ? draftData.gv.toString() : draftData.gv,
    gmi: draftData.gmi ? draftData.gmi.toString() : draftData.gmi,
    timeInRange: draftData.timeInRange ? draftData.timeInRange.toString() : draftData.timeInRange,
    timeBelowRange54: draftData.timeBelowRange54
      ? draftData.timeBelowRange54.toString()
      : draftData.timeBelowRange54,
    timeBelowRange70: draftData.timeBelowRange70
      ? draftData.timeBelowRange70.toString()
      : draftData.timeBelowRange70,
    timeAboveRange180: draftData.timeAboveRange180
      ? draftData.timeAboveRange180.toString()
      : draftData.timeAboveRange180,
    timeAboveRange250: draftData.timeAboveRange250
      ? draftData.timeAboveRange250.toString()
      : draftData.timeAboveRange250,
  };
};
