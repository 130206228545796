import { DeviceType } from 'common-src/models/PatientDevice';

export const FulfillmentStatus = Object.freeze({
  Open: 1,
  Shipped: 2,
  Complete: 3,
  Cancelled: 4,
  Failed: 5,
  Requested: 6,
});

export const fulfillmentProviders = {
  BioTel: {
    id: 2,
  },
  Transtek: {
    id: 3,
  },
};

export const fulfillmentProducts = {
  BioTelStrip50Ct: {
    id: 4,
    providerId: fulfillmentProviders.BioTel.id,
    sku: 'TT0003',
    lineName: 'BioTel Test Strips (50ct vial)',
    maxQuantity: 2,
  },
  BioTelStarterKit: {
    id: 5,
    providerId: fulfillmentProviders.BioTel.id,
    sku: 'TM0009',
    lineName: 'BioTel Care BGM',
    deviceId: DeviceType.BioTelGlucometer,
    maxQuantity: 1,
  },
  BioTelLancets100Ct: {
    id: 6,
    providerId: fulfillmentProviders.BioTel.id,
    sku: 'TL0001',
    lineName: 'BioTel 30 Gauge Lancets (100ct box)',
    maxQuantity: 2,
  },
  BioTelLancingDevice: {
    id: 7,
    providerId: fulfillmentProviders.BioTel.id,
    sku: 'LD0003',
    lineName: 'BioTel Lancing Device',
    maxQuantity: 1,
  },
  BioTelCharger: {
    id: 8,
    providerId: fulfillmentProviders.BioTel.id,
    sku: 'PO0004',
    lineName: 'BioTel Replacement Charger',
    maxQuantity: 1,
  },
  BioTelControlSolution: {
    id: 9,
    providerId: fulfillmentProviders.BioTel.id,
    sku: 'TC0003',
    lineName: 'BioTel Control Solution',
    maxQuantity: 1,
  },
  TranstekStarterKit: {
    id: 10,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '00850042929247',
    lineName: 'Transtek Starter Kits',
    maxQuantity: 1,
    stripsQuantity: 100,
  },
  Transtek4PkStrips: {
    id: 11,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '10850042929206',
    lineName: 'Transtek Test Strips(50ct): 4 Packs',
    maxQuantity: 1,
    stripsQuantity: 200,
  },
  Transtek4PkLancets: {
    id: 12,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '10850042929220',
    lineName: 'Transtek Test Lancets: 4 Packs',
    maxQuantity: 1,
    stripsQuantity: 0,
  },
  Transtek6PkStrips: {
    id: 13,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '20850042929203',
    lineName: 'Transtek Test Strips(50ct): 6 Packs',
    maxQuantity: 1,
    stripsQuantity: 300,
  },
  Transtek6PkLancets: {
    id: 14,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '20850042929227',
    lineName: 'Transtek Test Lancets: 6 Packs',
    maxQuantity: 1,
    stripsQuantity: 0,
  },
  Transtek10PkStrips: {
    id: 15,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '30850042929200',
    lineName: 'Transtek Test Strips(50ct): 10 Packs',
    maxQuantity: 1,
    stripsQuantity: 500,
  },
  Transtek10PkLancets: {
    id: 16,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '30850042929224',
    lineName: 'Transtek Test Lancets: 10 Packs',
    maxQuantity: 1,
    stripsQuantity: 0,
  },
  TranstekSinglePkStrips: {
    id: 17,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '00850042929209',
    lineName: 'Transtek Test Strips(50ct): 1 Pack',
    maxQuantity: 1,
    stripsQuantity: 50,
  },
  TranstekSinglePkLancets: {
    id: 18,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '00850042929223',
    lineName: 'Transtek Test Lancets: 1 Pack',
    maxQuantity: 1,
    stripsQuantity: 0,
  },
  TranstekSingleGlucometer: {
    id: 19,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '00850042929131',
    lineName: 'Transtek BGM',
    maxQuantity: 1,
    stripsQuantity: 0,
  },
  TranstekLancingDevice: {
    id: 20,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '00850042929230',
    lineName: 'Transtek Lancing Device',
    maxQuantity: 1,
    stripsQuantity: 0,
  },
  TranstekControlSolution: {
    id: 21,
    providerId: fulfillmentProviders.Transtek.id,
    sku: '00850042929216',
    lineName: 'Transtek Control Solution',
    maxQuantity: 1,
    stripsQuantity: 0,
  },
};
