import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  getPatientOncehubBookings,
  getPatientOncehubBookingsRequest,
} from 'common-src/models/PatientOncehubBooking';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { RequestHandlerScreen } from 'src/components/base';
import { TabsViewNew } from 'src/components/tabs';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';

import { ScheduledCallTabs } from './constants';
import { canceledFilter, getScheduledCallTabs } from './helpers';
import InfoBar from './InfoBar';
import styles from './ScheduledCalls.module.scss';
import ScheduledCallsList from './ScheduledCallsList';

const SCHEDULED_CALLS_POLLING_FREQUENCY = 10000;

const ScheduledCalls = ({ patientId }) => {
  const dispatch = useDispatch();

  const scheduledCallKeys = Object.keys(ScheduledCallTabs);
  const [selectedTab, setSelectedTab] = useState(scheduledCallKeys[0]);
  const [tabInfo, setTabInfo] = useState({});
  const [filteredOncehubBookings, setFilteredOncehubBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const oncehubBookings = useCustomSelector((state) => getPatientOncehubBookings(state, patientId));
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const timeZone = patient?.timeZone;

  const getFilteredOncehubBookings = () => {
    switch (selectedTab) {
      case scheduledCallKeys[0]: {
        return (
          oncehubBookings?.filter(
            (b) =>
              !moment().tz(timeZone).isAfter(moment(b.scheduledAt).tz(timeZone)) &&
              !canceledFilter(b),
          ) || []
        );
      }
      case scheduledCallKeys[1]: {
        return (
          oncehubBookings?.filter(
            (b) =>
              moment().tz(timeZone).isAfter(moment(b.scheduledAt).tz(timeZone)) &&
              !canceledFilter(b),
          ) || []
        );
      }
      case scheduledCallKeys[2]: {
        return oncehubBookings?.filter((b) => canceledFilter(b)) || [];
      }
      case scheduledCallKeys[3]: {
        return oncehubBookings;
      }
      default: {
        return [];
      }
    }
  };

  useInterval(() => {
    if (!patientId) return;
    dispatch(getPatientOncehubBookingsRequest({ patientId, sort_order: 'desc', limit: 5 }));
  }, SCHEDULED_CALLS_POLLING_FREQUENCY);

  useEffect(() => {
    setTabInfo(getScheduledCallTabs(oncehubBookings, timeZone));
    setFilteredOncehubBookings(getFilteredOncehubBookings());
  }, [oncehubBookings]);

  useEffect(() => {
    setFilteredOncehubBookings(getFilteredOncehubBookings());
  }, [selectedTab]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getPatientOncehubBookingsRequest({ patientId }, { successBlock: () => setIsLoading(false) }),
    );
  }, [patientId]);

  if (isLoading) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling scheduled calls..." />;
  }

  return (
    <section className={[styles.container, 'flex-column'].join(' ')}>
      <InfoBar patientId={patientId} />
      <TabsViewNew
        classNames={[styles.tabs, 'gap-30']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
      />
      <ScheduledCallsList scheduledCalls={filteredOncehubBookings} patientId={patientId} />
    </section>
  );
};

ScheduledCalls.propTypes = {
  patientId: PropTypes.number,
};

export default ScheduledCalls;
