import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import useOnClickOutside from 'common-src/hooks/useOnClickOutside';
import usePaper from 'common-src/hooks/usePaper';
import { ColorsNew } from 'common-src/styles';

import Input from '../Input';
import styles from './FormSelect.module.scss';
import { getPaperStyles } from './helpers';
import Option from './Option';

const FormSelect = ({
  id,
  value,
  label,
  prefix,
  iconSrc,
  errorText,
  description,
  paperHeight,
  options = [],
  classNames = [],
  multiple = false,
  required = false,
  readOnly = false,
  disabled = false,
  withError = true,
  onChange = () => {},
  isClearable = false,
  isSearchable = false,
  placeholder = 'Select',
  requiredColor = ColorsNew.mediumDarkRed,
}) => {
  const [searchText, setSearchText] = useState('');
  const [positionStyles, setPositionStyles] = useState({});
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showSearch, setShowSearch] = useState(true);

  const selectRef = useRef(null);
  const selectedOptions = useRef([]);
  const isMultiSelectClosed = useRef(null);

  const onCloseMultiSelect = () => {
    if (!multiple) return;

    setShowSearch(true);
    isMultiSelectClosed.current = true;
  };

  const { openPaper, closePaper, open } = usePaper(() => onCloseMultiSelect());
  const { ref: paperRef } = useOnClickOutside((e) => {
    const { top, right, bottom, left } = selectRef.current.getBoundingClientRect();
    // Do not close paper if user clicks inside of multiple select
    // Autofocus input field
    if (e.y < bottom && e.y > top && e.x > left && e.x < right && multiple) {
      setShowSearch(true);

      setTimeout(() => {
        document.getElementById(id).focus();
      }, 100);

      return;
    }

    setSearchText('');
    closePaper();
    onCloseMultiSelect();
  });

  useEffect(() => {
    if (!paperRef.current || !selectRef.current) return;
    setPositionStyles(getPaperStyles(paperRef, selectRef));
  }, [
    open,
    showSearch,
    paperRef.current,
    selectRef.current,
    window.innerWidth,
    window.innerHeight,
    filteredOptions.length,
    selectedOptions.current.length,
  ]);

  useEffect(() => {
    if (!isSearchable) return;

    setFilteredOptions(
      options.filter((op) => op.label.toLowerCase().startsWith(searchText.toLowerCase())),
    );
  }, [searchText]);

  useEffect(() => {
    if (!Array.isArray(value) || !multiple) return;
    selectedOptions.current = value;
  }, [multiple, JSON.stringify(value)]);

  useEffect(() => {
    if (!isMultiSelectClosed.current) return;
    onChange(selectedOptions.current);
  }, [isMultiSelectClosed.current]);

  const renderHeader = () => {
    if (!label) return null;

    return (
      <p
        className={['font-s-12', 'font-w-500', 'm-b-6', errorText ? styles.withError : ''].join(
          ' ',
        )}
      >
        {label}
        <span style={{ color: requiredColor }}>{required ? '*' : ''}</span>
      </p>
    );
  };

  const renderValue = () => {
    if (multiple) {
      if (_.isEmpty(selectedOptions.current) && !open) {
        return (
          <p
            id={id}
            className={[styles.placeholder, 'font-s-12', errorText ? styles.withError : ''].join(
              ' ',
            )}
          >
            {placeholder}
          </p>
        );
      }

      return (
        <div id={id} className={[styles.multipleValue, 'flex-row', 'gap-10'].join(' ')}>
          {selectedOptions.current.map((op) => (
            <div
              key={op.label}
              className={[
                styles.item,
                'flex-row',
                'vertically-centered',
                'gap-5',
                'border-r-4',
                'font-s-12',
              ].join(' ')}
            >
              <span>{op.label}</span>
              <img
                className={styles.closeIcon}
                src={CommonIcons.circleCloseIcon}
                alt="close-icon"
                role="presentation"
                onMouseDown={(e) => {
                  if (!open) {
                    e.stopPropagation();
                  }

                  const filteredOptions = selectedOptions.current.filter(
                    (x) => x.value !== op.value,
                  );
                  selectedOptions.current = filteredOptions;
                  onChange(filteredOptions);
                }}
              />
            </div>
          ))}
          {isSearchable && open && showSearch && (
            <Input
              classNames={[styles.inputWrapper, 'flex-1']}
              inputClassNames={[styles.input]}
              value={searchText}
              onTextChange={setSearchText}
              withError={false}
              autoFocus={open}
              size="small"
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </div>
      );
    }

    if (isSearchable && open) {
      return (
        <Input
          inputClassNames={[styles.input]}
          value={searchText}
          onTextChange={setSearchText}
          withError={false}
          autoFocus={open}
          size="small"
        />
      );
    }

    if (_.isEmpty(value)) {
      return <p className={[styles.placeholder, 'font-s-12'].join(' ')}>{placeholder}</p>;
    }

    return <p className={[styles.value, 'font-s-14'].join(' ')}>{value.label}</p>;
  };

  const renderResetButton = () => {
    if (
      !isClearable ||
      (!multiple && _.isEmpty(value)) ||
      (multiple && _.isEmpty(selectedOptions.current)) ||
      disabled
    )
      return null;

    return (
      <img
        className={styles.icon}
        src={CommonIcons.closeIcon}
        style={{ pointerEvents: 'auto' }}
        alt="reset-icon"
        role="presentation"
        onClick={(e) => {
          onChange(multiple ? [] : null);

          if (!open) {
            e.stopPropagation();
          }
        }}
      />
    );
  };

  const renderContent = () => {
    if (readOnly) {
      return (
        <p id={id} className={['font-w-500', 'font-s-14'].join(' ')}>
          {multiple ? value.map((x) => x.label).join(', ') : value?.label || '-'}
        </p>
      );
    }

    return (
      <div
        ref={selectRef}
        className={[
          styles.select,
          disabled ? styles.disabled : '',
          errorText ? styles.withError : '',
          'flex-row',
          'vertically-centered',
          'secondary-border',
          'border-r-6',
          'gap-5',
        ].join(' ')}
        onClick={(e) => {
          if (disabled) return;
          openPaper(e);
          isMultiSelectClosed.current = false;
        }}
        onMouseDown={() => {
          if (!open || multiple) return;
          setTimeout(() => {
            closePaper();
          }, 50);
        }}
        role="presentation"
      >
        {iconSrc && <img className={styles.icon} src={iconSrc} alt="icon" />}
        {prefix && <span className="font-s-14">{prefix}:</span>}
        {renderValue()}
        <div className={[styles.iconsWrapper, 'flex-row', 'vertically-centered'].join(' ')}>
          {renderResetButton()}
          <img
            style={{ mixBlendMode: errorText ? 'difference' : 'normal' }}
            src={CommonIcons.arrowDownIcon}
            alt="arrow-icon"
          />
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    if (!description || !!errorText) return null;

    return <span className={['font-w-400', 'font-s-12', 'm-t-4'].join(' ')}>{description}</span>;
  };

  const renderError = () => {
    if (!withError) return null;

    return (
      <span className={[styles.error, 'm-t-4', 'font-w-400', 'font-s-12'].join(' ')}>
        {errorText}
      </span>
    );
  };

  const onOptionClick = (e, op) => {
    if (multiple) {
      e.stopPropagation();
      setShowSearch(false);

      if (selectedOptions.current.find((o) => o.value === op.value)) {
        selectedOptions.current = selectedOptions.current.filter((o) => o.value !== op.value);
        return;
      }

      selectedOptions.current = [...selectedOptions.current, op];
      return;
    }

    closePaper();
    e.stopPropagation();
    onChange(op);
  };

  const renderOptions = () => {
    if (_.isEmpty(options) || (isSearchable && _.isEmpty(filteredOptions))) {
      return <p className={[styles.emptyPlaceholder, 'font-s-14'].join(' ')}>No options</p>;
    }

    const opts = isSearchable ? filteredOptions : options;

    return opts.map((op) => (
      <Option
        key={`${op.label}-${op.value}`}
        option={op}
        isSelected={
          multiple
            ? !!selectedOptions.current.find((o) => o?.value === op.value)
            : op.value === value?.value
        }
        onClick={(e) => onOptionClick(e, op)}
      />
    ));
  };

  const renderPaper = () => {
    if (!open) return null;

    return (
      <div
        ref={paperRef}
        id="select-paper"
        className={styles.paper}
        style={{ ...positionStyles, maxHeight: paperHeight && `${paperHeight}px` }}
      >
        {renderOptions()}
      </div>
    );
  };

  return (
    <div className={[styles.container, 'relative', ...classNames].join(' ')}>
      {renderHeader()}
      {renderContent()}
      {renderDescription()}
      {renderError()}
      {renderPaper()}
    </div>
  );
};

FormSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  prefix: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  withError: PropTypes.bool,
  iconSrc: PropTypes.string,
  errorText: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  description: PropTypes.string,
  paperHeight: PropTypes.number,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  classNames: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  requiredColor: PropTypes.string,
};

export default FormSelect;
