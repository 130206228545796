/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Menu } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import { getClient } from 'common-src/models/Client';
import PatientFulfillmentOrder, {
  fulfillmentProviders,
  FulfillmentStatus,
  getFulfillmentStatusConfig,
  markAsCanceled,
  markAsFailed,
} from 'common-src/models/PatientFulfillmentOrder';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useOpenWindow from 'src/hooks/useOpenWindow';

import styles from './Item.module.scss';

const Item = ({ order = {} }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const placedBy = useCustomSelector((state) => getClient(state, order?.orderedBy));
  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.devicesAndOrders,
  );

  const { showAlert, AlertType } = useAlert();
  const { openWindow } = useOpenWindow();

  const renderTextContainer = () => (
    <div className={[styles.textContainer, 'flex-column'].join(' ')}>
      <span
        id="order-placed-by"
        className={[styles.orderedBy, 'font-w-600', 'font-s-12'].join(' ')}
      >
        {!_.isEmpty(placedBy) ? placedBy?.getName(false, true) : '-'}
      </span>
      <span id="order-creation-date" className={[styles.date, 'font-w-700', 'font-s-16'].join(' ')}>
        {moment(order?.createdAt).format('MM/DD/YYYY')}
      </span>
      <span
        id="order-number"
        className={[styles.number, 'font-w-500', 'm-t-2', 'font-s-12'].join(' ')}
      >
        {order?.id || '-'}
      </span>
    </div>
  );

  const renderStatus = () => (
    <div
      className={[
        styles.button,
        'vertically-centered',
        'font-w-500',
        'gap-6',
        'primary-border',
        'font-s-14',
      ].join(' ')}
    >
      <img src={getFulfillmentStatusConfig(order?.orderStatus).iconSrc} alt="status-icon" />
      <span id="order-status">{getFulfillmentStatusConfig(order?.orderStatus).text}</span>
    </div>
  );

  const renderTrackingButton = () => {
    if (!order?.trackingId) return null;

    return (
      <div
        id="order-tracking-link-id"
        className={[
          styles.button,
          'vertically-centered',
          'font-w-500',
          'gap-6',
          'primary-border',
          'font-s-14',
        ].join(' ')}
        onClick={() =>
          openWindow({
            url: `https://www.google.com/search?q=${order.trackingId}`,
            target: '_blank',
            features: 'noopener,noreferrer',
          })
        }
      >
        <img src={CommonIcons.journeyIcon} alt="tracking-prefix-icon" />
        <span>Tracking</span>
        <img className={styles.shippingIcon} src={CommonIcons.linkOutIcon} alt="link-icon" />
      </div>
    );
  };

  const renderMenu = () => {
    const options = _.compact([
      {
        id: 'view-order',
        text: 'View order',
        onClick: async () => dispatch(openModal(ModalType.ORDER_DETAILS, { order })),
      },
      actions?.markAsCanceled &&
        order.provider === fulfillmentProviders.Transtek.id &&
        order.orderStatus === FulfillmentStatus.Open && {
          id: 'mark-as-canceled',
          text: 'Cancel Order',
          onClick: async () => {
            setIsLoading(true);
            dispatch(
              markAsCanceled(order.id, {
                successBlock: () => {
                  showAlert(AlertType.Success, '', 'Successfully cancelled order');
                  setIsLoading(false);
                },
                errorBlock: (err) => {
                  showAlert(AlertType.Error, '', err);
                  setIsLoading(false);
                },
              }),
            );
          },
        },
      actions?.markAsFailed &&
        [FulfillmentStatus.Shipped, FulfillmentStatus.Requested].includes(order.orderStatus) && {
          id: 'mark-as-failed',
          text: 'Mark as Failed',
          onClick: async () => {
            setIsLoading(true);
            dispatch(
              markAsFailed(order.id, {
                successBlock: () => {
                  showAlert(AlertType.Success, '', 'Successfully marked as failed');
                  setIsLoading(false);
                },
                errorBlock: (err) => {
                  showAlert(AlertType.Error, '', err);
                  setIsLoading(false);
                },
              }),
            );
          },
        },
    ]);

    return (
      <Menu
        classNames={[styles.menu, 'm-l-8']}
        button={
          <img
            id="icon-btn"
            src={CommonIcons.actions}
            className={['icon-btn'].join(' ')}
            alt="actions-button"
          />
        }
        options={options}
        isLoading={isLoading}
      />
    );
  };

  return (
    <div className={[styles.container, 'gap-10', 'primary-border'].join(' ')}>
      <img className={styles.icon} src={CommonIcons.shippingIcon} alt="shipping-icon" />
      {renderTextContainer()}
      {renderStatus()}
      {renderTrackingButton()}
      {renderMenu()}
    </div>
  );
};

Item.propTypes = {
  order: PropTypes.exact(PatientFulfillmentOrder.schema),
};

export default Item;
