import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { FormSelect, RadioGroup, TextArea } from 'common-src/components/base';
import {
  getActivePatientSdohNames,
  getSocialIssueValuesByNames,
  SocialIssues,
} from 'common-src/models/PatientSdoh';

import useCustomSelector from 'src/hooks/useCustomSelector';
import commonStyles from 'src/pages/DocumentationIndex/commonStyles.module.scss';
import { FormHeader } from 'src/pages/DocumentationIndex/components';

import { initialSdohState } from './constants';
import styles from './SdohModule.module.scss';

const SdohModule = ({ patientId, sdoh, setSdoh, setHasDetectedChanges, classNames = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const activeSdohNames = useCustomSelector((state) => getActivePatientSdohNames(state, patientId));

  const sdohData = useMemo(() => {
    const patientSdoh = getSocialIssueValuesByNames(activeSdohNames);
    const description = _.isEmpty(activeSdohNames)
      ? 'Select an area of concern'
      : `Current patient social issues: ${patientSdoh.join(', ')}`;
    const options = _.isEmpty(activeSdohNames)
      ? SocialIssues
      : _.difference(SocialIssues, _.intersection(patientSdoh, SocialIssues));

    return {
      description,
      options: options.map((option) => ({ value: option, label: option })),
    };
  }, [activeSdohNames]);

  useEffect(() => {
    if (_.isEmpty(sdoh)) {
      setSdoh(initialSdohState);
    }
  }, [sdoh]);

  const onChange = (field, value) => {
    setSdoh((prev) => ({ ...prev, [field]: value }));
    setHasDetectedChanges(new Date());
  };

  const renderContent = () => {
    if (!isExpanded) return null;

    return (
      <div className={styles.wrapper}>
        <p className={[styles.instructions, 'font-s-14', 'm-b-20'].join(' ')}>
          Based on your conversation with the patient, are there any areas of SDOH that should be
          reviewed or potentially addressed by a case worker?
        </p>
        <div className={['m-l-20'].join(' ')}>
          <FormSelect
            id="sdoh-issues"
            classNames={[commonStyles.multiSelect]}
            label="Social issue"
            placeholder="Select an area of concern"
            description={sdohData.description}
            options={sdohData.options}
            value={sdohData.options.filter((option) => sdoh.issues?.includes(option.value))}
            onChange={(selectedOptions) =>
              onChange(
                'issues',
                selectedOptions.map((option) => option.value),
              )
            }
            required={false}
            multiple
          />
          <p className={[styles.label, 'font-s-14', 'font-w-500', 'm-b-16'].join(' ')}>
            Patient interested in discussing with case worker?
          </p>
          <RadioGroup
            id="sdoh-interested-in-discussing"
            classNames={[commonStyles.radio, commonStyles.row]}
            containerClassNames={['flex-row', 'gap-16', 'vertically-centered', 'm-b-24']}
            options={['Yes', 'No', 'Not Sure']}
            value={sdoh.interestedInDiscussing}
            onChange={(interestedInDiscussing) =>
              onChange('interestedInDiscussing', interestedInDiscussing)
            }
            onClear={() => onChange('interestedInDiscussing', null)}
          />
          <TextArea
            id="sdoh-additional-details"
            classNames={[commonStyles.input]}
            inputClassNames={[commonStyles.inputText]}
            label="Additional details:"
            placeholder="Provide additional details for the Case Worker to review"
            required={false}
            value={sdoh.additionalDetails}
            maxRows={16}
            fixedRows={6}
            onFinishEditing={(additionalDetails) =>
              onChange('additionalDetails', additionalDetails)
            }
            debounced
            withError={false}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={[...classNames].join(' ')}>
      <FormHeader
        title="Social Determinants of Health (SDOH)"
        isExpanded={isExpanded}
        onClick={() => setIsExpanded((prev) => !prev)}
      />
      {renderContent()}
    </div>
  );
};

SdohModule.propTypes = {
  patientId: PropTypes.number,
  sdoh: PropTypes.object,
  setSdoh: PropTypes.func,
  setHasDetectedChanges: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default SdohModule;
