// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrendedResultsPopup-module__wrapper___e2pLa {
  padding: 20px 0 10px 30px;
}
.TrendedResultsPopup-module__wrapper___e2pLa .TrendedResultsPopup-module__tabs___gKHhT {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/popups/TrendedResultsPopup/TrendedResultsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AACE;EACE,UAAA;AACJ","sourcesContent":[".wrapper {\n  padding: 20px 0 10px 30px;\n\n  .tabs {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TrendedResultsPopup-module__wrapper___e2pLa`,
	"tabs": `TrendedResultsPopup-module__tabs___gKHhT`
};
module.exports = ___CSS_LOADER_EXPORT___;
