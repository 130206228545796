import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { unavailableActionsStatuses } from 'common-src/models/Patient/constants';
import { getPatientDrafts } from 'common-src/models/PatientInteraction';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import {
  getIsRunningLatestVersion,
  getShouldShowUpdateModal,
  setShouldShowUpdateModal,
  VersionUpdateMessage,
} from 'src/features/appVersion';
import { closeModal, getModalsState, openModal, resetModalState } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePrevious from 'src/hooks/usePrevious';
import AddClientPopup from 'src/popups/AddClientPopup';
import AddPlanPopup from 'src/popups/AddPlanPopup';
import AddTaskPopup from 'src/popups/AddTaskPopup';
import AllergyDetailsPopup from 'src/popups/AllergyDetailsPopup';
import AllergyPopup from 'src/popups/AllergyPopup';
import AwscCallDispositionPopup from 'src/popups/AwscCallDispositionPopup';
import CGMAuthPopup from 'src/popups/CGMAuthPopup';
import CGMOrderPopup from 'src/popups/CGMOrderPopup';
import ConfirmReassignmentPopup from 'src/popups/ConfirmReassignmentPopup';
import DateOfServicePopup from 'src/popups/DateOfServicePopup';
import DocumentationWarningPopup from 'src/popups/DocumentationWarningPopup';
import EditPlanPopup from 'src/popups/EditPlanPopup';
import EditStripsPopup from 'src/popups/EditStripsPopup';
import EditTaskPopup from 'src/popups/EditTaskPopup';
import EditVisitPopup from 'src/popups/EditVisitPopup';
import EncounterDetailsPopup from 'src/popups/EncounterDetailsPopup';
import EncounterPopup from 'src/popups/EncounterPopup';
import ExemptionPopup from 'src/popups/ExemptionPopup';
import FamilyHistoryPopup from 'src/popups/FamilyHistoryPopup';
import InteractionAdjustmentsPopup from 'src/popups/InteractionAdjustmentsPopup';
import LabPopup from 'src/popups/LabPopup';
import ManualCGMOrderPopup from 'src/popups/ManualCGMOrderPopup';
import MaxTabLimitPopup from 'src/popups/MaxTabLimitPopup/MaxTabLimitPopup';
import MedicalProblemPopup from 'src/popups/MedicalProblemPopup';
import MedicationDetailsPopup from 'src/popups/MedicationDetailsPopup';
import MedicationPopup from 'src/popups/MedicationPopup';
import NoteConfirmationPopup from 'src/popups/NoteConfirmationPopup';
import OrderDetailsPopup from 'src/popups/OrderDetailsPopup';
import OrderStripsPopup from 'src/popups/OrderStripsPopup';
import PatientAddressPopup from 'src/popups/PatientAddressPopup';
import PatientDevicePopup from 'src/popups/PatientDevicePopup';
import PatientDevicesPopup from 'src/popups/PatientDevicesPopup';
import PatientStatusPopup from 'src/popups/PatientStatusPopup';
import PreAuthPopup from 'src/popups/PreAuthPopup';
import ProblemDetailsPopup from 'src/popups/ProblemDetailsPopup';
import RequestAdjustmentsPopup from 'src/popups/RequestAdjustmentsPopup';
import ResultsDetailsPopup from 'src/popups/ResultsDetailsPopup';
import SdohPopup from 'src/popups/SdohPopup';
import SendDocumentationPopup from 'src/popups/SendDocumentationPopup';
import SmartGoalPopup from 'src/popups/SmartGoalPopup';
import SupplementDetailsPopup from 'src/popups/SupplementDetailsPopup';
import SupplementPopup from 'src/popups/SupplementPopup';
import SurgicalHistoryDetailsPopup from 'src/popups/SurgicalHistoryDetailsPopup';
import SurgicalHistoryPopup from 'src/popups/SurgicalHistoryPopup';
import TrendedResultsPopup from 'src/popups/TrendedResultsPopup';
import VitalsPopup from 'src/popups/VitalsPopup';
import WarningPopup from 'src/popups/WarningPopup';

import {
  CLOSE_MODAL_DELAY,
  CLOSE_MODAL_DELAY_AFTER_SUCCESS,
  ModalType,
  withDocumentationWarning,
  withPatientStatusCheck,
} from './constants';

const ModalGroup = () => {
  const dispatch = useDispatch();

  const [isContinuePressed, setIsContinuePressed] = useState(false);

  const modalsState = useCustomSelector((state) => getModalsState(state));
  const patient = useCustomSelector((state) =>
    singleModelSelector(state, 'Patient', modalsState?.patientId),
  );
  const drafts = useCustomSelector((state) => getPatientDrafts(state, modalsState?.patientId));
  const isRunningLatestVersion = useCustomSelector(getIsRunningLatestVersion);
  const shouldShowUpdateModal = useCustomSelector(getShouldShowUpdateModal);

  const prevDrafts = usePrevious(drafts);

  useEffect(() => {
    if (_.isEmpty(modalsState.activeModals)) {
      setIsContinuePressed(false);
    }
  }, [modalsState.activeModals]);

  useEffect(() => {
    if (!isRunningLatestVersion && shouldShowUpdateModal) {
      dispatch(openModal(ModalType.APP_UPDATE));
    }
  }, [isRunningLatestVersion, shouldShowUpdateModal, modalsState]);

  const onCloseHandler = (modalType, inSuccessBlock) => {
    const delay =
      typeof inSuccessBlock === 'boolean' ? CLOSE_MODAL_DELAY_AFTER_SUCCESS : CLOSE_MODAL_DELAY;
    setTimeout(() => {
      dispatch(closeModal(modalType));
    }, delay);
  };

  const renderModal = ({ modalName, props }, patient) => {
    if (
      withPatientStatusCheck.includes(modalName) &&
      unavailableActionsStatuses.includes(patient?.platformStatus) &&
      !isContinuePressed
    ) {
      return (
        <PatientStatusPopup
          key="patient-status"
          open
          patientStatus={patient?.platformStatus}
          onClose={() => dispatch(closeModal(modalName))}
          onSubmit={() => {
            setTimeout(() => {
              dispatch(openModal(modalName, props));
              setIsContinuePressed(true);
            }, CLOSE_MODAL_DELAY);
          }}
        />
      );
    }

    if (withDocumentationWarning.includes(modalName) && !_.isEmpty(drafts)) {
      return (
        <DocumentationWarningPopup
          key="documentation-warning"
          open
          patientId={patient?.id}
          drafts={drafts}
          onClose={() => dispatch(resetModalState())}
          onSubmit={() => {
            dispatch(resetModalState());
            props.callback();
          }}
        />
      );
    }

    switch (modalName) {
      case ModalType.ALLERGY:
        return (
          <AllergyPopup
            key="allergy"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ALLERGY, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.ALLERGY_DETAILS:
        return (
          <AllergyDetailsPopup
            key="allergy-details"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ALLERGY_DETAILS, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.MAX_TAB_LIMIT:
        return (
          <MaxTabLimitPopup
            key="max-tab-limit"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.MAX_TAB_LIMIT, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.ADD_CLIENT:
        return (
          <AddClientPopup
            key="add-client"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ADD_CLIENT, inSuccessBlock)}
          />
        );
      case ModalType.MEDICATION:
        return (
          <MedicationPopup
            key="medication"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.MEDICATION, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.MEDICATION_DETAILS:
        return (
          <MedicationDetailsPopup
            key="medication-details"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.MEDICATION_DETAILS, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.CGM_ORDER:
        return (
          <CGMOrderPopup
            key="cgm-order"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.CGM_ORDER, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.MANUAL_CGM_ORDER:
        return (
          <ManualCGMOrderPopup
            key="manual-cgm-order"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.MANUAL_CGM_ORDER, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.CGM_AUTH:
        return (
          <CGMAuthPopup
            key="cgm-auth"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.CGM_AUTH, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.ENCOUNTER:
        return (
          <EncounterPopup
            key="encounter"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ENCOUNTER, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.ENCOUNTER_DETAILS:
        return (
          <EncounterDetailsPopup
            key="encounter-deitals"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.ENCOUNTER_DETAILS, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.NOTICE:
      case ModalType.ADD_ADDENDUM:
      case ModalType.REMOVE_LAB_RESULT:
      case ModalType.ADD_NOTE:
        setTimeout(() => {
          dispatch(resetModalState());

          if ([ModalType.ADD_ADDENDUM].includes(modalName) && !_.isEmpty(prevDrafts)) return null;

          props.callback();
        }, 0);

        return null;
      case ModalType.SURGICAL_HISTORY:
        return (
          <SurgicalHistoryPopup
            key="surgical-history"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.SURGICAL_HISTORY, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.SURGICAL_HISTORY_DETAILS:
        return (
          <SurgicalHistoryDetailsPopup
            key="surgical-history-details"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.SURGICAL_HISTORY_DETAILS, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.SMART_GOAL:
        return (
          <SmartGoalPopup
            key="smart-goal"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.SMART_GOAL, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.SUPPLEMENT:
        return (
          <SupplementPopup
            key="supplement"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.SUPPLEMENT, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.SUPPLEMENT_DETAILS:
        return (
          <SupplementDetailsPopup
            key="supplement-details"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.SUPPLEMENT_DETAILS, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.FAMILY_HISTORY:
        return (
          <FamilyHistoryPopup
            key="family-history"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.FAMILY_HISTORY, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.WARNING:
        return (
          <WarningPopup
            key="warning"
            onClose={() => dispatch(resetModalState())}
            {...props}
            onSubmit={() => {
              dispatch(resetModalState());
              props?.onSubmit();
            }}
          />
        );
      case ModalType.ORDER_DETAILS:
        return (
          <OrderDetailsPopup
            key="order-details"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ORDER_DETAILS, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.PATIENT_DEVICES:
        return (
          <PatientDevicesPopup
            key="patient-devices"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.PATIENT_DEVICES, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.TRENDED_RESULTS:
        return (
          <TrendedResultsPopup
            key="trended-results"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.TRENDED_RESULTS, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.RESULTS_DETAILS:
        return (
          <ResultsDetailsPopup
            key="results-details"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.RESULTS_DETAILS, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.MEDICAL_PROBLEM:
        return (
          <MedicalProblemPopup
            key="medical-problem"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.MEDICAL_PROBLEM, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.PROBLEM_DETAILS:
        return (
          <ProblemDetailsPopup
            key="problem-details"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.PROBLEM_DETAILS, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.CONFIRM_REASSIGNMENT:
        return (
          <ConfirmReassignmentPopup
            key="confirm-reassignment"
            open
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.CONFIRM_REASSIGNMENT, inSuccessBlock)
            }
            {...props}
            onSubmit={() => {
              props?.onSubmit();
            }}
          />
        );
      case ModalType.SEND_DOCUMENTATION:
        return (
          <SendDocumentationPopup
            key="send-documentation"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.SEND_DOCUMENTATION, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.APP_UPDATE:
        return (
          <WarningPopup
            key="app-update"
            title="App Update Needed"
            message={VersionUpdateMessage}
            submitButtonText="Reload"
            onSubmit={() => window.location.reload()}
            onClose={() => {
              dispatch(closeModal(ModalType.APP_UPDATE));
              dispatch(setShouldShowUpdateModal(false));
            }}
          />
        );
      case ModalType.EDIT_STRIP_COUNT: {
        return (
          <EditStripsPopup
            key="edit-strips"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.EDIT_STRIP_COUNT, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.PATIENT_DEVICE:
        return (
          <PatientDevicePopup
            key="patient-device"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.PATIENT_DEVICE, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.PATIENT_ADDRESS: {
        return (
          <PatientAddressPopup
            key="patient-address"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.PATIENT_ADDRESS, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.ORDER_STRIPS: {
        return (
          <OrderStripsPopup
            key="order-strips"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ORDER_STRIPS, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.PRE_AUTH: {
        return (
          <PreAuthPopup
            key="pre-auth"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.PRE_AUTH, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.ADD_PLAN: {
        return (
          <AddPlanPopup
            key="add-plan"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ADD_PLAN, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.EDIT_PLAN: {
        return (
          <EditPlanPopup
            key="edit-plan"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.EDIT_PLAN, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.EXEMPTION: {
        return (
          <ExemptionPopup
            key="exemption"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.EXEMPTION, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.NOTE_CONFIRMATION:
        return (
          <NoteConfirmationPopup
            key="note-confirmation"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.NOTE_CONFIRMATION, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.VITALS: {
        return (
          <VitalsPopup
            key="vitals"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.VITALS, inSuccessBlock)}
            {...props}
          />
        );
      }
      case ModalType.LAB:
        return (
          <LabPopup
            key="lab"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.LAB, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.DATE_OF_SERVICE:
        return (
          <DateOfServicePopup
            key="date-of-service"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.DATE_OF_SERVICE, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.SDOH:
        return (
          <SdohPopup
            key="sdoh"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.SDOH, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.EDIT_TASK:
        return (
          <EditTaskPopup
            key="edit-task"
            open
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.EDIT_TASK, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.ADD_TASK:
        return (
          <AddTaskPopup
            key="add-task"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.ADD_TASK, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.REQUEST_ADJUSTMENTS:
        return (
          <RequestAdjustmentsPopup
            key="request-adjustments"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.REQUEST_ADJUSTMENTS, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.INTERACTION_ADJUSTMENTS:
        return (
          <InteractionAdjustmentsPopup
            key="interaction-adjustments"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.INTERACTION_ADJUSTMENTS, inSuccessBlock)
            }
            {...props}
          />
        );
      case ModalType.EDIT_VISIT:
        return (
          <EditVisitPopup
            key="edit-visit"
            onClose={(inSuccessBlock) => onCloseHandler(ModalType.EDIT_VISIT, inSuccessBlock)}
            {...props}
          />
        );
      case ModalType.AWSC_CALL_DISPOSITION:
        return (
          <AwscCallDispositionPopup
            key="awsc-call-disposition"
            onClose={(inSuccessBlock) =>
              onCloseHandler(ModalType.AWSC_CALL_DISPOSITION, inSuccessBlock)
            }
            {...props}
          />
        );
      default:
        return null;
    }
  };

  return <>{modalsState.activeModals.map((modal) => renderModal(modal, patient))}</>;
};

ModalGroup.propTypes = {
  callback: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalGroup;
