import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientMedication extends Model {
  static modelName = 'PatientMedication';

  static fields = {
    id: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    med: attr(),
    dosage: attr(),
    name: attr(),
    form: attr(),
    instructions: attr(),
    status: attr(),
    source: attr(),
    frequency: attr(),
    route: attr(),
    activeTill: attr(),
    filledDate: attr(),
    startDate: attr(),
    endDate: attr(),
    notes: attr(),
    prescriber: attr(),
    pharmacy: attr(),
    duration: attr(),
    refill: attr(),
    fillNumber: attr(),
    updatedBy: fk('Client', 'medicationUpdater'),
    createdBy: fk('Client', 'medicationCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'medications',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      med: PropTypes.object,
      dosage: PropTypes.string,
      name: PropTypes.string,
      form: PropTypes.string,
      instructions: PropTypes.string,
      status: PropTypes.number,
      source: PropTypes.number,
      frequency: PropTypes.number,
      route: PropTypes.number,
      activeTill: PropTypes.string,
      filledDate: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      notes: PropTypes.string,
      prescriber: PropTypes.string,
      pharmacy: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientMedication;
