import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { validatePatientEmergencyContact } from 'common-src/models/PatientEmergencyContact';
import { getPatientEmergencyContacts } from 'common-src/models/PatientEmergencyContact/selectors';
import { getFormattedPhoneNumber } from 'common-src/models/PatientPhoneNumber/helpers';
import { cleanedText, PHONE_MASK } from 'common-src/presenters';

import { AddButton, RemoveButton } from 'src/components/buttons';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from '../commonStyles.module.scss';
import {
  catchErrors,
  extractEmergencyContact,
  getInitialEmergencyContactData,
  getRequests,
  resetEmergencyContactErrors,
} from './helpers';

const EmergencyContacts = ({ patientId }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState([]);

  const emergencyContacts = useCustomSelector((state) =>
    getPatientEmergencyContacts(state, patientId),
  );

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    dispatch(
      restRequestMultiple({
        restRequests: getRequests(formData, emergencyContacts),
        successBlock: () => {
          setIsEditMode(false);
          setIsLoading(false);
          showAlert(AlertType.Success, '', 'You have successfully updated emergency contacts.');
        },
        errorBlock: (err) => {
          setIsLoading(false);
          showAlert(AlertType.Error, '', err);
        },
      }),
    );
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'emergency-contacts',
  );

  useEffect(() => {
    const data = !_.isEmpty(emergencyContacts)
      ? emergencyContacts.map((contact) => extractEmergencyContact(contact))
      : [];

    setInitialData(data);
    setFormData(data);
  }, [emergencyContacts]);

  const handleInputChange = (field, newValue) => {
    const validationField = field.split('.')[1];
    const err = validatePatientEmergencyContact(validationField, newValue);

    handleErrors({ [field]: err[validationField] });
    setFormData((prev) => _.set(prev, field, newValue === null ? null : newValue));
  };

  const renderRemoveButton = (contact) => {
    if (!isEditMode) return <div />;

    return (
      <div className="flex-row">
        <RemoveButton
          id="remove-emergency-contact"
          onClick={() => {
            handleErrors(resetEmergencyContactErrors(formData, contact));
            setFormData((prev) => _.without(prev, contact));
          }}
        />
        <div />
      </div>
    );
  };

  const renderAddButton = () => {
    if (!isEditMode) return null;

    return (
      <AddButton
        id="add-emergency-contact"
        classNames={[styles.button]}
        label="Add Emergency Contact"
        onClick={() => setFormData((prev) => [...prev, getInitialEmergencyContactData(patientId)])}
      />
    );
  };

  const renderForm = () => (
    <>
      {formData.map((contact, index) => (
        <div key={`emergency-contact-${index}`} className={styles.grid}>
          <Input
            id={`emergency-contact-name-${index}`}
            readOnly={!isEditMode}
            value={contact.name}
            label="Full Name"
            placeholder="Enter Full Name"
            required
            onTextChange={(value) => handleInputChange(`${index}.name`, value)}
            errorText={errors[`${index}.name`]}
            size="small"
          />
          <Input
            id={`emergency-contact-relationship-${index}`}
            readOnly={!isEditMode}
            value={contact.relationship}
            label="Relationship to Member"
            placeholder="Enter Relationship to Member"
            required
            onTextChange={(value) => handleInputChange(`${index}.relationship`, value)}
            errorText={errors[`${index}.relationship`]}
            size="small"
          />
          <Input
            id={`emergency-contact-phone-${index}`}
            readOnly={!isEditMode}
            value={isEditMode ? contact.phone : getFormattedPhoneNumber(contact.phone)}
            label="Phone Number"
            placeholder="Enter phone number"
            required
            onTextChange={(value) => handleInputChange(`${index}.phone`, cleanedText(value))}
            errorText={errors[`${index}.phone`]}
            mask={PHONE_MASK}
            size="small"
          />
          {renderRemoveButton(contact)}
        </div>
      ))}
      <div className={styles.grid}>{renderAddButton()}</div>
    </>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

EmergencyContacts.propTypes = {
  patientId: PropTypes.number,
};

export default EmergencyContacts;
