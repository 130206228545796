export const AlertType = Object.freeze({
  Success: 'Success',
  Error: 'Error',
  Warning: 'Warning',
});

export const DismissTime = Object.freeze({
  [AlertType.Success]: 2500,
  [AlertType.Error]: 5000,
  [AlertType.Warning]: 5000,
});
