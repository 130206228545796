import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './LinkItem.module.scss';

const LinkItem = ({ title, small, moduleId, linkedKey, selectedLink, setSelectedLink }) => {
  const linkClasses = [styles.link, 'font-w-500', 'font-s-14'];
  if (small) {
    linkClasses.push('font-s-12');
  }

  if (_.isEqual(selectedLink[moduleId], linkedKey)) {
    linkClasses.push('font-w-600');
  }

  const onClickHandler = (e) => {
    e.stopPropagation();

    setSelectedLink({ [moduleId]: linkedKey });

    const element = document.getElementById(linkedKey);
    setTimeout(() => {
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  return (
    <div
      className={linkClasses.join(' ')}
      key={linkedKey}
      role="presentation"
      onClick={(e) => onClickHandler(e)}
    >
      {title}
    </div>
  );
};

LinkItem.propTypes = {
  small: PropTypes.bool,
  title: PropTypes.string,
  moduleId: PropTypes.number,
  linkedKey: PropTypes.string,
  selectedLink: PropTypes.object,
  setSelectedLink: PropTypes.func,
};

export default LinkItem;
