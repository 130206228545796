export const SdohStatus = Object.freeze({
  Active: {
    id: 1,
    value: 1,
    label: 'Active',
    textColor: '#ffffff',
    backgroundColor: '#9D97C7',
  },
  Addressing: {
    id: 2,
    value: 2,
    label: 'Addressing',
    textColor: '#ffffff',
    backgroundColor: '#F1BF72',
  },
  Resolved: {
    id: 3,
    value: 3,
    label: 'Resolved',
    textColor: '#ffffff',
    backgroundColor: '#637768',
  },
  Canceled: {
    id: 4,
    value: 4,
    label: 'Canceled',
    textColor: '#ffffff',
    backgroundColor: '#F26083',
  },
  ToAddress: {
    id: 5,
    value: 5,
    label: 'To Address',
    textColor: '#ffffff',
    backgroundColor: '#F06C67',
  },
  Addressed: {
    id: 6,
    value: 6,
    label: 'Addressed',
    textColor: '#ffffff',
    backgroundColor: '#9D97C7',
  },
});

export const activeStatusesIds = [
  SdohStatus.Active.id,
  SdohStatus.Addressing.id,
  SdohStatus.ToAddress.id,
  SdohStatus.Addressed.id,
];

export const SocialIssues = [
  'Z59.00 Homelessness unspecified',
  'Z59.10 Inadequate housing unspecified',
  'Z59.41 Food insecurity',
  'Z75.3 Unavailability and inaccessibility of health care',
  'Z59.86 Financial insecurity',
  'Z59.82 Transportation insecurity',
  'Unspecified, other',
];
