import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

import { roles } from './constants';

class Client extends Model {
  static modelName = 'Client';

  static fields = {
    id: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    firstName: attr(),
    middleName: attr(),
    lastName: attr(),
    suffix: attr(),
    email: attr(),
    npi: attr(),
    credentials: attr(),
    isSuper: attr(),
    offboardedAt: attr(),
    roleId: attr(),
    awscAgentId: attr(),
    orgId: fk({
      to: 'Organization',
      as: 'organization',
      relatedName: 'clients',
    }),
  };

  get role() {
    const role = Object.values(roles).find((role) => role.id === this.roleId);

    return role ? role.label : '';
  }

  getName(withRole = false, withMiddleName = false) {
    if (this.id === 1) return 'System user';

    const firstName = this.firstName || '';
    const lastName = this.lastName || '';
    const name =
      withMiddleName && this.middleName
        ? `${firstName} ${this.middleName.charAt(0)}. ${lastName}`
        : `${firstName} ${lastName}`;

    return withRole ? `${name}, ${this.role}` : name;
  }

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      suffix: PropTypes.string,
      email: PropTypes.string,
      npi: PropTypes.string,
      credentials: PropTypes.string,
      isSuper: PropTypes.bool,
      offboardedAt: PropTypes.string,
      roleId: PropTypes.number,
      awscAgentId: PropTypes.string,
      orgId: PropTypes.number,
    }),
    orgId: PropTypes.number,
  };
}

export default Client;
