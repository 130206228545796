import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew, Input, TextArea } from 'common-src/components/base';
import { apiRequest } from 'common-src/features/rest';
import { getGmiAndGv, getPatientGlucoseValues } from 'common-src/models/PatientCgmGlucoseReading';
import { getPatientLabResults } from 'common-src/models/PatientLabReading';
import { getAge } from 'common-src/utils/dateUtils';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import commonStyles from 'src/pages/DocumentationIndex/commonStyles.module.scss';
import { getLastLabResults } from 'src/pages/PatientDetails/fragments/Biometrics/tabs/CGMTab/helpers';

import styles from './CGMEvaluationModule.module.scss';
import { CGMEvaluationFieldIds } from './constants';

const CGMEvaluationModule = ({
  patientId,
  cgmEvaluation,
  setCgmEvaluation,
  errors,
  setHasDetectedChanges,
  classNames = [],
}) => {
  const [cgmData, setCgmData] = useState({});
  const [shouldReset, setShouldReset] = useState(false);

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const labResults = useCustomSelector((state) => getPatientLabResults(state, patientId));

  const { lastHbA1c } = getLastLabResults(labResults);

  const areValuesEditable =
    !cgmEvaluation?.startDate || !cgmEvaluation?.endDate || _.isEmpty(cgmData);

  const updateGlucoseValues = (glucoseValues, gmiAndGv) => {
    setCgmEvaluation((prev) => ({
      ...prev,
      timeBelowRange70: glucoseValues?.low?.value || null,
      timeBelowRange54: glucoseValues?.veryLow?.value || null,
      gv: gmiAndGv?.glucoseVariability?.value || null,
      gmi: gmiAndGv?.gmi?.value || null,
      timeInRange: glucoseValues?.targetRange?.value || null,
      timeAboveRange180: glucoseValues?.high?.value || null,
      timeAboveRange250: glucoseValues?.veryHigh?.value || null,
    }));
  };

  // set initial dates
  useEffect(() => {
    if (_.isEmpty(cgmEvaluation)) {
      setCgmEvaluation((prev) => ({
        ...prev,
        startDate: moment().subtract(14, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }));
    }
  }, [cgmEvaluation]);

  useEffect(() => {
    if (cgmEvaluation?.startDate && cgmEvaluation?.endDate) {
      apiRequest({
        endpoint: 'patientCgmGlucoseReadings/aggregate',
        queryParams: {
          patient_id: patientId,
          end_date: moment(cgmEvaluation?.endDate).format('YYYY-MM-DD'),
          start_date: moment(cgmEvaluation?.startDate).format('YYYY-MM-DD'),
        },
      })
        .then((res) => res.json())
        .then((cgmData) => {
          setCgmData(!cgmData?.detail ? cgmData : {});
        });
    } else {
      updateGlucoseValues(null, null);
    }
  }, [cgmEvaluation?.startDate, cgmEvaluation?.endDate]);

  useEffect(() => {
    if (_.isEmpty(cgmData)) return;

    const gmiAndGv = getGmiAndGv(cgmData, getAge(patient?.dob), lastHbA1c?.value);
    const glucoseValues = getPatientGlucoseValues(cgmData, getAge(patient?.dob), lastHbA1c?.value);

    // reset values and then set new ones
    setShouldReset(true);
    setTimeout(() => {
      setShouldReset(false);
      updateGlucoseValues(glucoseValues, gmiAndGv);
    }, 10);
  }, [cgmData]);

  const onChange = (field, value) => {
    setCgmEvaluation((prev) => ({ ...prev, [field]: value }));
    setHasDetectedChanges(new Date());
  };

  const applyDate = (field, date) => {
    const dateToApply = date ? moment(date).format('YYYY-MM-DD') : null;
    onChange(field, dateToApply);
  };

  const onChangeMetrics = (field, value) => {
    const v = typeof value === 'number' ? value.toString() : value;
    onChange(field, v || null);
  };

  return (
    <div className={[styles.container, 'flex-column', ...classNames].join(' ')}>
      <span className={['font-w-600', 'font-s-16'].join(' ')}>Evaluation Metrics</span>
      <span className={['font-w-500', 'font-s-14', 'p-t-12', 'p-b-20'].join(' ')}>
        Dates Evaluated
      </span>
      <div className="flex-column gap-50">
        <DatePickerNew
          containerId={CGMEvaluationFieldIds.startDateContainer}
          id={CGMEvaluationFieldIds.startDate}
          classNames={[styles.width50, commonStyles.date]}
          inputClassNames={[commonStyles.inputDateContainer]}
          textClassNames={[commonStyles.dateInput]}
          header="Start Date"
          placeholder="MM/DD/YYYY"
          minDate={
            cgmEvaluation?.endDate
              ? new Date(moment(cgmEvaluation.endDate).subtract(28, 'days'))
              : undefined
          }
          value={cgmEvaluation.startDate}
          iconSrc={CommonIcons.calendarCheckBoldIcon}
          onDateSelected={(date) => applyDate('startDate', date)}
          onBlur={(date) => applyDate('startDate', date)}
          errorText={errors.get(CGMEvaluationFieldIds.startDateContainer)}
          required
        />
        <DatePickerNew
          containerId={CGMEvaluationFieldIds.endDateContainer}
          id={CGMEvaluationFieldIds.endDate}
          classNames={[styles.width50, commonStyles.date]}
          inputClassNames={[commonStyles.inputDateContainer]}
          textClassNames={[commonStyles.dateInput]}
          header="End Date"
          placeholder="MM/DD/YYYY"
          maxDate={
            cgmEvaluation?.startDate
              ? new Date(moment(cgmEvaluation.startDate).add(28, 'days'))
              : new Date()
          }
          value={cgmEvaluation.endDate}
          iconSrc={CommonIcons.calendarCheckBoldIcon}
          onDateSelected={(date) => applyDate('endDate', date)}
          onBlur={(date) => applyDate('endDate', date)}
          errorText={errors.get(CGMEvaluationFieldIds.endDateContainer)}
          required
        />
      </div>
      <span className={['font-w-500', 'font-s-14', 'p-t-50', 'p-b-20'].join(' ')}>Metrics</span>
      <Input
        id={CGMEvaluationFieldIds.timeBelowRange70}
        classNames={[styles.width50]}
        inputClassNames={[commonStyles.inputNumber]}
        type="number"
        label="% Time Below Range (< 70 mg/dl)"
        value={cgmEvaluation.timeBelowRange70 || ''}
        onFinishEditing={(value) => onChangeMetrics('timeBelowRange70', value)}
        disabled={!areValuesEditable}
        shouldReset={shouldReset}
        debounced
        isFloat
      />
      <Input
        id={CGMEvaluationFieldIds.timeBelowRange54}
        classNames={[styles.width50]}
        inputClassNames={[commonStyles.inputNumber]}
        type="number"
        label="% Time Below Range (< 54 mg/dl)"
        value={cgmEvaluation.timeBelowRange54 || ''}
        onFinishEditing={(value) => onChangeMetrics('timeBelowRange54', value)}
        disabled={!areValuesEditable}
        shouldReset={shouldReset}
        debounced
        isFloat
      />
      <Input
        id={CGMEvaluationFieldIds.gv}
        classNames={[styles.width50]}
        inputClassNames={[commonStyles.inputNumber]}
        type="number"
        label="GV (goal < 36%)"
        value={cgmEvaluation.gv || ''}
        onFinishEditing={(value) => onChangeMetrics('gv', value)}
        disabled={!areValuesEditable}
        shouldReset={shouldReset}
        debounced
        isFloat
      />
      <Input
        id={CGMEvaluationFieldIds.gmi}
        classNames={[styles.width50]}
        inputClassNames={[commonStyles.inputNumber]}
        type="number"
        label="GMI"
        value={cgmEvaluation.gmi || ''}
        onFinishEditing={(value) => onChangeMetrics('gmi', value)}
        disabled={!areValuesEditable}
        shouldReset={shouldReset}
        debounced
        isFloat
      />
      <Input
        id={CGMEvaluationFieldIds.timeInRange}
        classNames={[styles.width50]}
        inputClassNames={[commonStyles.inputNumber]}
        type="number"
        label="% Time In Range (70 - 180 mg/dl)"
        value={cgmEvaluation.timeInRange || ''}
        onFinishEditing={(value) => onChangeMetrics('timeInRange', value)}
        disabled={!areValuesEditable}
        shouldReset={shouldReset}
        debounced
        isFloat
      />
      <Input
        id={CGMEvaluationFieldIds.timeAboveRange180}
        classNames={[styles.width50]}
        inputClassNames={[commonStyles.inputNumber]}
        type="number"
        label="% Time Above Range (> 180 mg/dl)"
        value={cgmEvaluation.timeAboveRange180 || ''}
        onFinishEditing={(value) => onChangeMetrics('timeAboveRange180', value)}
        disabled={!areValuesEditable}
        shouldReset={shouldReset}
        debounced
        isFloat
      />
      <Input
        id={CGMEvaluationFieldIds.timeAboveRange250}
        classNames={[styles.width50]}
        inputClassNames={[commonStyles.inputNumber]}
        type="number"
        label="% Time Above Range (> 250 mg/dl)"
        value={cgmEvaluation.timeAboveRange250 || ''}
        onFinishEditing={(value) => onChangeMetrics('timeAboveRange250', value)}
        disabled={!areValuesEditable}
        shouldReset={shouldReset}
        debounced
        isFloat
      />
      <TextArea
        id={CGMEvaluationFieldIds.additionalDetails}
        inputClassNames={[commonStyles.inputText]}
        label="Additional details"
        placeholder="Up to 255 characters"
        value={cgmEvaluation.additionalDetails}
        maxRows={8}
        fixedRows={3}
        onFinishEditing={(value) => onChange('additionalDetails', value)}
        debounced
        withError={false}
      />
    </div>
  );
};

CGMEvaluationModule.propTypes = {
  patientId: PropTypes.number,
  cgmEvaluation: PropTypes.object,
  setCgmEvaluation: PropTypes.func,
  errors: PropTypes.object,
  setHasDetectedChanges: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default CGMEvaluationModule;
