// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertSettings-module__form___eDROY {
  display: grid;
  column-gap: 16px;
  margin-bottom: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Orders/forms/AlertSettings/AlertSettings.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,kCAAA;AACF","sourcesContent":[".form {\n  display: grid;\n  column-gap: 16px;\n  margin-bottom: 16px;\n  grid-template-columns: 1fr 1fr 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `AlertSettings-module__form___eDROY`
};
module.exports = ___CSS_LOADER_EXPORT___;
