// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddTaskPopup-module__wrapper___lDy4R {
  padding: 25px 30px;
}

.AddTaskPopup-module__buttonsContainer___rGSoW {
  gap: 16px;
  padding: 30px;
  display: flex;
  justify-content: end;
  border-top: 1px solid rgba(91, 91, 91, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/popups/AddTaskPopup/AddTaskPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,SAAA;EACA,aAAA;EACA,aAAA;EACA,oBAAA;EACA,2CAAA;AACF","sourcesContent":[".wrapper {\n  padding: 25px 30px;\n}\n\n.buttonsContainer {\n  gap: 16px;\n  padding: 30px;\n  display: flex;\n  justify-content: end;\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddTaskPopup-module__wrapper___lDy4R`,
	"buttonsContainer": `AddTaskPopup-module__buttonsContainer___rGSoW`
};
module.exports = ___CSS_LOADER_EXPORT___;
