import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { RadioGroup } from 'common-src/components/base';
import { getCurrentRoleId } from 'common-src/features/auth';
import { apiRequest, getRestErrorMessage, HttpMethods } from 'common-src/features/rest';

import { TaskType } from 'src/components/widgets/AwsConnectDialer/constants';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import { DispositionOptions } from 'src/popups/AwscCallDispositionPopup/constants';
import BasePopup from 'src/popups/BasePopup';

import styles from './AwscCallDispositionPopup.module.scss';

const AwscCallDispositionPopup = ({ awscCallId, source, onClose }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const { renderButtons } = usePopup();

  const roleId = useCustomSelector((state) => getCurrentRoleId(state));
  const dispositionConfig = DispositionOptions[roleId];
  const isDismissable = dispositionConfig?.isDismissable || false;
  const options = Object.values(TaskType).includes(source)
    ? dispositionConfig?.taskOptions?.[source]
    : dispositionConfig?.callOptions;

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    apiRequest({
      endpoint: `awscCall/${awscCallId}/disposition`,
      method: HttpMethods.Put,
      body: {
        disposition: selectedOption,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const errMsg = getRestErrorMessage(data);
        if (errMsg) {
          errorCallback(errMsg);
          return;
        }

        successCallback('Successfully updated call disposition.');
        onClose();
      });
  };

  return (
    <BasePopup
      id="awsc-call-disposition"
      customStyle={{ minWidth: '0px', width: '330px' }}
      open
      onClose={onClose}
      closeOnOverlayClick={false}
      showCloseIcon={isDismissable}
      title="Select call disposition"
    >
      <div className={styles.container}>
        <RadioGroup
          id="disposition-options-radio-group"
          options={options || []}
          value={selectedOption}
          onChange={(value) => setSelectedOption(value)}
          classNames={[styles.radioItem]}
          applyBackgroundColor
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose: isDismissable ? onClose : null,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: selectedOption !== null,
        submitButtonText: 'Submit',
        cancelButtonText: 'Skip',
      })}
    </BasePopup>
  );
};

AwscCallDispositionPopup.propTypes = {
  awscCallId: PropTypes.string,
  source: PropTypes.string,
  onClose: PropTypes.func,
};

export default AwscCallDispositionPopup;
