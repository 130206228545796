import PropTypes from 'prop-types';
import React, { useState } from 'react';

import PatientInteraction from 'common-src/models/PatientInteraction';

import { FormHeader } from 'src/pages/DocumentationIndex/components';

import styles from './LinkedVsitsModule.module.scss';
import ScheduledVisits from './ScheduledVisits';

const LinkedVsitsModule = ({
  patientId,
  isEncounterNotScheduled,
  setIsEncounterNotScheduled,
  linkedVisitId,
  setLinkedVisitId,
  documentationToEdit,
  setHasDetectedChanges,
  classNames = [],
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const renderContent = () => {
    if (!isExpanded) return null;

    return (
      <div className={styles.wrapper}>
        <ScheduledVisits
          patientId={patientId}
          isEncounterNotScheduled={isEncounterNotScheduled}
          setIsEncounterNotScheduled={(isEncounterNotScheduled) => {
            setIsEncounterNotScheduled(isEncounterNotScheduled);
            setHasDetectedChanges(new Date());
          }}
          linkedVisitId={linkedVisitId}
          setLinkedVisitId={(linkedVisitId) => {
            setLinkedVisitId(linkedVisitId);
            setHasDetectedChanges(new Date());
          }}
          documentationToEdit={documentationToEdit}
        />
      </div>
    );
  };

  return (
    <div className={[...classNames].join(' ')}>
      <FormHeader
        title="Linked visits"
        isExpanded={isExpanded}
        onClick={() => setIsExpanded((prev) => !prev)}
      />
      {renderContent()}
    </div>
  );
};

LinkedVsitsModule.propTypes = {
  patientId: PropTypes.number,
  isEncounterNotScheduled: PropTypes.bool,
  setIsEncounterNotScheduled: PropTypes.func,
  linkedVisitId: PropTypes.number,
  setLinkedVisitId: PropTypes.func,
  documentationToEdit: PropTypes.exact(PatientInteraction.schema),
  setHasDetectedChanges: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default LinkedVsitsModule;
