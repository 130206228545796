import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from 'common-src/components/base';
import { moduleTypes } from 'common-src/models/PatientInteraction';
import { TemplateItemType } from 'common-src/models/Template';

import { shouldDisplayTemplateItem } from 'src/pages/DocumentationIndex/helpers';

import LinkItem from '../LinkItem';
import styles from './ModuleItem.module.scss';

const ModuleItem = ({
  moduleId,
  setSelectedLink,
  selectedLink,
  onChangeHandler,
  template = {},
}) => {
  const moduleTitle =
    Object.values(moduleTypes).find((moduleType) => moduleType.id === moduleId).title ?? '';

  const getLabel = () => {
    if (!template.isSelected) return moduleTitle;

    return (
      <LinkItem
        linkedKey={`module-${moduleId}`}
        title={moduleTitle}
        selectedLink={selectedLink}
        moduleId={moduleId}
        setSelectedLink={setSelectedLink}
      />
    );
  };

  const renderSections = () => {
    const sections =
      template.isSelected &&
      template.items
        ?.filter(
          (item) =>
            item.itemType === TemplateItemType.Heading &&
            item.size === 16 &&
            shouldDisplayTemplateItem(item.displayCondition, template.items),
        )
        .map((item) => (
          <LinkItem
            key={`heading-${moduleId}-${item.text}`}
            linkedKey={`heading-${moduleId}-${item.text.toLowerCase()}`}
            title={item.text}
            small
            selectedLink={selectedLink}
            moduleId={moduleId}
            setSelectedLink={setSelectedLink}
          />
        ));

    if (!sections || _.isEmpty(sections.filter((i) => i))) return null;

    return (
      <div className={[styles.instructions, 'gap-10', 'flex-column'].join(' ')}>{sections}</div>
    );
  };

  return (
    <div
      className={[styles.checkboxWrapper, template.isSelected ? styles.selected : ''].join(' ')}
      key={moduleId}
    >
      <Checkbox
        id={`module-${moduleTitle.split(' ').join('-').toLowerCase()}`}
        checked={template.isSelected || false}
        onChange={onChangeHandler}
        label={getLabel()}
      />
      {renderSections()}
    </div>
  );
};

ModuleItem.propTypes = {
  moduleId: PropTypes.number,
  selectedLink: PropTypes.object,
  setSelectedLink: PropTypes.func,
  onChangeHandler: PropTypes.func,
  template: PropTypes.object,
};

export default ModuleItem;
