import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, RequestHandlerScreen } from 'common-src/components/base';
import { getCurrentUserId } from 'common-src/features/auth';
import { updateClientRequest, validateClient } from 'common-src/models/Client';
import { Colors, ColorsNew } from 'common-src/styles';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { AvatarCircle } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './MyAccountScreen.module.scss';

const requiredFields = ['firstName', 'lastName', 'email'];
const disabledFields = ['firstName', 'middleName', 'lastName', 'suffix', 'email', 'id'];
const formFields = ['firstName', 'middleName', 'lastName', 'suffix', 'email', 'id'];

const MyAccountScreen = () => {
  const dispatch = useDispatch();

  const [client, setClient] = useState({});
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const currentClient = useCustomSelector((state) =>
    singleModelSelector(state, 'Client', getCurrentUserId(state)),
  );

  const { showAlert, AlertType } = useAlert();

  useEffect(() => {
    if (currentClient?.id) {
      setClient(_.pick(currentClient, formFields));
    }
  }, [currentClient]);

  const handleInputChange = (text, attr, required) => {
    if (!saveButtonEnabled) setSaveButtonEnabled(true);

    if (required) {
      const err = validateClient(attr, text);
      setErrors((prev) => ({ ...prev, ...err }));
    }

    const updatedClient = { ...client, [attr]: text };
    setClient(updatedClient);
  };

  const handleError = (error) => {
    setIsLoading(false);
    showAlert(AlertType.Error, '', error.errMsg || error);
  };

  const handleSuccess = (message) => {
    setIsLoading(false);
    showAlert(AlertType.Success, '', message);
  };

  const renderTextInput = (header, placeholder, attrField) => {
    const required = requiredFields.includes(attrField);
    return (
      <div className={styles.item}>
        <Input
          id={attrField}
          required={required}
          disabled={disabledFields.includes(attrField)}
          classNames={[styles.input]}
          errorText={errors[attrField]}
          label={header}
          onTextChange={(text) => handleInputChange(text, attrField, required)}
          value={client[attrField] || ''}
          placeholder={placeholder}
          mask={null}
          size="small"
        />
      </div>
    );
  };

  const submitHandler = () => {
    setIsLoading(true);
    dispatch(
      updateClientRequest(
        currentClient.id,
        {
          ...client,
          id: undefined, // INFO: do not include client id in request body
          npi: currentClient.npi,
          credentials: currentClient.credentials,
          roleId: currentClient.roleId,
        },
        {
          successBlock: () => {
            setSaveButtonEnabled(false);
            handleSuccess('Success. My Account Info Updated!');
          },
          errorBlock: (error) => handleError(error),
        },
      ),
    );
  };

  const getIsButtonEnabled = () => !saveButtonEnabled || _.isEmpty(errors);

  if (!currentClient?.id) {
    return <RequestHandlerScreen isRequesting />;
  }

  return (
    <div className={[styles.container, 'flex-column'].join(' ')}>
      <div className={styles.header}>
        <p className={styles.title}>Profile</p>
        <span className={styles.subtitle}>Profile picture</span>
        <AvatarCircle
          user={currentClient}
          classNames={[styles.avatar, 'border-r-50']}
          iconSize="90px"
        />
      </div>
      <div className={styles.inputRow}>
        {renderTextInput('First Name', 'Enter first name', 'firstName')}
        {renderTextInput('Middle Name', 'Enter middle name', 'middleName')}
        {renderTextInput('Last Name', 'Enter last name', 'lastName')}
        {renderTextInput('Suffix', 'Enter suffix', 'suffix')}
      </div>
      <div className={styles.inputRow}>
        {renderTextInput('Client Id', 'Client id', 'id')}
        {renderTextInput('Email Address', 'Enter email address', 'email')}
      </div>
      <Button
        id="save-button"
        classNames={[styles.button]}
        isLoading={isLoading}
        disabled={getIsButtonEnabled()}
        text="Save changes"
        textColor={!getIsButtonEnabled() || isLoading ? Colors.white : Colors.black}
        backgroundColor={ColorsNew.darkGreen}
        borderColor={ColorsNew.darkGreen}
        onClick={submitHandler}
      />
    </div>
  );
};

export default MyAccountScreen;
