// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkedVsitsModule-module__wrapper___nIFjw {
  margin: 0 30px 30px 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DocumentationIndex/modules/LinkedVsitsModule/LinkedVsitsModule.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":[".wrapper {\n  margin: 0 30px 30px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LinkedVsitsModule-module__wrapper___nIFjw`
};
module.exports = ___CSS_LOADER_EXPORT___;
