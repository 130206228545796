export const SAVE_PATIENT_DETAILS_TAB_INFO = 'tabsState/SAVE_PATIENT_DETAILS_TAB_INFO';
export const SAVE_SETTINGS_TAB_INFO = 'tabsState/SAVE_SETTINGS_TAB_INFO';
export const RESET_TAB_INFO = 'tabsState/RESET_TAB_INFO';
export const RESET_DATA_ON_CLOSE = 'tabsState/RESET_DATA_ON_CLOSE';
export const RESET_TABS_INFO = 'tabsState/RESET_TABS_INFO';
export const SAVE_IS_PATIENT_SIDEBAR_EXTENDED = 'tabsState/SAVE_IS_PATIENT_SIDEBAR_EXTENDED';
export const SAVE_PATIENT_DOCUMENTATION_TAB_INFO = 'tabsState/SAVE_PATIENT_DOCUMENTATION_TAB_INFO';
export const SAVE_PATIENT_TIMELINE_TAB_INFO = 'tabsState/SAVE_PATIENT_TIMELINE_TAB_INFO';
export const SAVE_PATIENT_LAST_MEDICATIONS_PULL = 'tabsState/SAVE_PATIENT_LAST_MEDICATIONS_PULL';
export const SAVE_PATIENT_MAIN_TAB = 'tabsState/SAVE_PATIENT_MAIN_TAB';
export const CLOSE_PATIENT_DETAILS = 'tabsState/CLOSE_PATIENT_DETAILS';
export const SET_ACTIVE_TABS = 'tabsState/SET_ACTIVE_TABS';
export const SAVE_PATIENT_NOTE = 'tabsState/SAVE_PATIENT_NOTE';
export const SAVE_NOTE_DATA = 'tabsState/SAVE_NOTE_DATA';
export const SAVE_NOTE_SCROLL = 'tabsState/SAVE_NOTE_SCROLL';
export const SAVE_DASHBOARD_FILTERS = 'tabsState/SAVE_DASHBOARD_FILTERS';
export const SAVE_IS_CURIE_AI_SELECTED = 'tabsState/SAVE_IS_CURIE_AI_SELECTED';
export const SAVE_IS_DRAFT_SAVING = 'tabsState/SAVE_IS_DRAFT_SAVING';
export const SAVE_IS_DRAFT_DELETING = 'tabsState/SAVE_IS_DRAFT_DELETING';
