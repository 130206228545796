// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InteractionAdjustmentsPopup-module__container___u01C7 {
  padding: 25px 30px;
}

.InteractionAdjustmentsPopup-module__buttonsContainer___DuH5o {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/InteractionAdjustmentsPopup/InteractionAdjustmentsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AACF","sourcesContent":[".container {\n  padding: 25px 30px;\n}\n\n.buttonsContainer {\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n  justify-content: end;\n  padding: 30px;\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InteractionAdjustmentsPopup-module__container___u01C7`,
	"buttonsContainer": `InteractionAdjustmentsPopup-module__buttonsContainer___DuH5o`
};
module.exports = ___CSS_LOADER_EXPORT___;
