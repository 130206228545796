/* eslint-disable no-case-declarations */
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { apiRequest, HttpMethods } from 'common-src/features/rest';
import { getClient } from 'common-src/models/Client';
import { platformStatusPresenter } from 'common-src/models/Patient';
import { CGMOrderStatus, CGMProducts, CGMSuppliers } from 'common-src/models/PatientCgmOrder';
import PatientHistoryEvent from 'common-src/models/PatientHistoryEvent';
import { formatTime, getDateWithTimeZone } from 'common-src/utils/dateUtils';

import Icons from 'src/assets/Icons';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useOpenWindow from 'src/hooks/useOpenWindow';

import { AppointmentType, CallMethod, ChatDirection, MessageTypes } from '../constants';
import {
  deviceOrderStatusPresenter,
  getDocumentationModuleNames,
  getMessageTypeIcon,
} from '../helpers';
import styles from './MessageBubble.module.scss';

const MessageBubble = ({ event }) => {
  const { id, patient, createdAt, typeId, meta, senderId } = event;
  const clientId = meta?.clientId || senderId;

  const isOutbound = useMemo(() => {
    switch (typeId) {
      case MessageTypes.AwscCall:
        return [CallMethod.Outbound, CallMethod.ExternalOutbound, CallMethod.Callback].includes(
          meta?.initiationMethod,
        );
      case MessageTypes.AwscChatMessage:
        return meta?.direction === ChatDirection.Outbound;
      default:
        return patient.id !== clientId;
    }
  }, [typeId, patient, clientId, meta]);

  const sender = useCustomSelector((state) => getClient(state, clientId)) || {};

  const { openWindow } = useOpenWindow();

  const containerClassNames = [styles.container, 'm-b-22', 'gap-12'];
  const textClassNames = [styles.text, 'font-w-500', 'font-s-12'];
  const bubbleClassNames = [
    styles.bubble,
    'flex-column',
    'font-w-500',
    'gap-6',
    'primary-border',
    'font-s-16',
  ];
  if (isOutbound) {
    containerClassNames.push(styles.right);
    textClassNames.push(styles.right);
    bubbleClassNames.push(styles.right);
  }

  const getSenderName = (withRole, firstNameOnly) => {
    const { role } = sender;

    if ([MessageTypes.TextMessage, MessageTypes.CompletedCall].includes(typeId)) {
      if (meta.senderName) {
        return withRole && role ? `${meta.senderName}, ${role}` : meta.senderName;
      }
      return 'Automated';
    }

    if (sender) {
      const name = firstNameOnly ? sender?.firstName || '' : sender?.getName();
      return name !== 'System user' && withRole && role ? `${name}, ${role}` : name;
    }

    return 'Automated';
  };

  const openPresignedUrl = (id) => {
    apiRequest({
      endpoint: `awscCall/presignedUrl/${id}`,
      method: HttpMethods.Get,
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Error while getting Presigned URL for recording.');
        }

        return res.json(); // The FastAPI endpoint returns a plain text string
      })
      .then((presignedUrl) => {
        openWindow({ url: presignedUrl.replace('"', ''), target: '_blank' });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderLink = (route, label, onClick = () => {}) => (
    <Link
      className={[styles.link, 'gap-6', 'font-s-14'].join(' ')}
      to={`/dashboard-client/patients/details/${patient.id}/${route}`}
      onClick={onClick}
    >
      <span>{label}</span>
      <img src={Icons.arrowRightIcon} alt="arrow-right" />
    </Link>
  );

  const renderBubbleContent = () => {
    let body;
    let title;
    switch (typeId) {
      case MessageTypes.Documentation:
        body = (
          <div id={`doc-${meta.interactionId}`}>
            <p>{getDocumentationModuleNames(meta.moduleTypeIds)}</p>
            {renderLink('conversation-history', 'Open Documentation', () => {
              openWindow({
                url: `/dashboard-client/documentation/details/${patient.id}/${meta.interactionId}/read`,
                features: 'width=1200,height=800,left=300,top=100',
              });
            })}
          </div>
        );
        title = 'Documentation';
        break;
      case MessageTypes.PlatformStatus:
      case MessageTypes.HoldRequest:
        body = (
          <>
            <p>
              {typeId === MessageTypes.HoldRequest
                ? 'Hold Requested'
                : platformStatusPresenter(meta.platformStatus)}
            </p>
            {renderLink('info', 'Open Member Info')}
          </>
        );
        title = 'Program Status Change';
        break;
      case MessageTypes.TextMessage: {
        body = <p className="break-word">{meta.content}</p>;
        const name = isOutbound ? getSenderName(true) : getSenderName(false);
        title = name === 'Automated' ? 'Automated message' : `Message from ${name}`;
        break;
      }
      case MessageTypes.MissedCall:
        body = <p>{`Missed ${isOutbound ? 'Outbound' : 'Inbound'} Call`}</p>;
        title = 'Call message';
        break;
      case MessageTypes.CompletedCall:
        body = (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="m-r-40">{`${isOutbound ? 'Outbound' : 'Inbound'} Call`}</p>
              <span>{formatTime(meta.duration)}</span>
            </div>
            <p>{meta.disposition}</p>
            {meta.recordingLink &&
              renderLink('conversation-history', 'Call Recording', () => {
                openWindow({ url: meta.recordingLink, target: '_blank' });
              })}
          </>
        );
        title = getSenderName(true);
        break;
      case MessageTypes.Appointment: {
        body = (
          <>
            <p>
              {meta.visitType}: {getDateWithTimeZone(meta.when, 'MM/DD/YY [at] h:mm a')}
            </p>
            {renderLink('scheduled-calls', 'Open Scheduled Visits')}
          </>
        );
        title = `${AppointmentType[meta.status]} appointment with ${getSenderName(false, true)}`;
        break;
      }
      case MessageTypes.DeviceOrdered:
      case MessageTypes.DeviceShipped:
      case MessageTypes.DeviceDelivered:
        body = <span>Status: {deviceOrderStatusPresenter(typeId)}</span>;
        title = meta.isStarterKit ? 'Starter Kit' : 'Refresh Package';
        break;
      case MessageTypes.ClinicalAlert:
        body = (
          <>
            <span>{meta.readingLevel} mg/dL</span>
            {renderLink('glucose-readings', 'Open Glucose Readings')}
          </>
        );
        title = 'Glucose Reading Alert';
        break;
      case MessageTypes.Reminder:
        body = <span>{meta.notes || ''}</span>;
        title = 'Reminder';
        break;
      case MessageTypes.CgmUsageAlert:
        body = (
          <>
            <span>No data received for 72 hours</span>
            {renderLink('glucose-readings', 'Open Glucose Readings')}
          </>
        );
        title = 'CGM Usage Alert';
        break;
      case MessageTypes.SmartGoalCreated:
        body = (
          <>
            <span>
              {_.isEmpty(meta?.goal) || meta?.goal === 'Deleted goal'
                ? 'Deleted goal'
                : `Created a "${meta?.goal || ''}" goal`}
            </span>
            {renderLink('smart-goals', 'Open SMART Goals')}
          </>
        );
        title = 'SMART Goal created';
        break;
      case MessageTypes.SmartGoalAccomplished:
        body = (
          <>
            <span>
              {_.isEmpty(meta?.goal) || meta?.goal === 'Deleted goal'
                ? 'Deleted goal'
                : `Accomplished "${meta?.goal || ''}" goal`}
            </span>
            {renderLink('smart-goals', 'Open SMART Goals')}
          </>
        );
        title = 'SMART Goal accomplished';
        break;
      case MessageTypes.CgmOrder:
        body = (
          <div id={`doc-${meta.interactionId}`}>
            <p className="m-b-2">
              Supplier:{' '}
              {Object.values(CGMSuppliers).find((s) => s.value === meta?.supplier)?.label || ''}
            </p>
            <p className="m-b-2">
              Status:{' '}
              {Object.values(CGMOrderStatus).find((s) => s.value === meta?.status)?.label || ''}
            </p>
            <p className="m-b-2">
              Order Date: {meta?.orderedAt ? moment(meta.orderedAt).format('MM/DD/YYYY') : '-'}
            </p>
            <p className="m-b-6">
              Date Shipped: {meta?.shippedAt ? moment(meta.shippedAt).format('MM/DD/YYYY') : '-'}
            </p>
            {renderLink('cgmOrders', 'Open CGM Orders')}
          </div>
        );
        title = `CGM Device Order: ${
          Object.values(CGMProducts).find((p) => p.value === meta?.product)?.label || ''
        }`;
        break;
      case MessageTypes.AwscCall:
        const { id, agentInteractionDuration, interactionId, hasRecordingUrl } = meta;
        const callTime = agentInteractionDuration || 0;

        body = (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="m-r-40">{`${isOutbound ? 'Outbound' : 'Inbound'} Call`}</p>
              <span>{callTime ? formatTime(callTime) : '--:--:--'}</span>
            </div>
            {interactionId &&
              renderLink('conversation-history', 'Open Documentation', () => {
                openWindow({
                  url: `/dashboard-client/documentation/details/${patient.id}/${interactionId}/read`,
                  features: 'width=1200,height=800,left=300,top=100',
                });
              })}
            {hasRecordingUrl &&
              renderLink('conversation-history', 'Call Recording', () => {
                openPresignedUrl(id);
              })}
          </div>
        );
        title = isOutbound ? getSenderName(true) : patient.getName();
        break;
      case MessageTypes.AwscChatMessage:
        body = <p className="break-word">{meta?.message || '-'}</p>;
        const name = isOutbound ? getSenderName(true) : patient.getName();
        title = name === 'Automated' ? 'Automated message' : name;
        break;
      default:
        body = null;
        title = '';
    }

    return (
      <>
        <p className={[styles.bubbleTitle, 'font-s-12'].join(' ')}>{title}</p>
        {body}
      </>
    );
  };

  if (!Object.values(MessageTypes).includes(typeId)) {
    return null;
  }

  const getCreatedAt = () => {
    const date = typeId === MessageTypes.AwscChatMessage ? meta?.sentAt : createdAt;
    return getDateWithTimeZone(date, 'h:mm A MM/DD/YYYY z');
  };

  return (
    <div id={`event-${id}`} className={containerClassNames.join(' ')}>
      <img
        id={isOutbound ? 'outbound-icon' : 'inbound-icon'}
        className={[styles.icon, 'm-b-22'].join(' ')}
        src={getMessageTypeIcon(typeId, isOutbound)}
        alt="icon"
      />
      <div className={[styles.wrapper, 'flex-column', 'gap-6'].join(' ')}>
        <div className={bubbleClassNames.join(' ')}>{renderBubbleContent()}</div>
        <span className={textClassNames.join(' ')}>{getCreatedAt()}</span>
      </div>
    </div>
  );
};

MessageBubble.propTypes = {
  event: PropTypes.exact(PatientHistoryEvent.schema),
};

export default MessageBubble;
