// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Exemptions-module__tableCell___dQbTo {
  padding: 8px 0;
}

.Exemptions-module__actionButton___QqQlR {
  height: 38px;
  margin: -8px 0 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Exemptions/Exemptions.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,YAAA;EACA,oBAAA;AACF","sourcesContent":[".tableCell {\n  padding: 8px 0;\n}\n\n.actionButton {\n  height: 38px;\n  margin: -8px 0 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableCell": `Exemptions-module__tableCell___dQbTo`,
	"actionButton": `Exemptions-module__actionButton___QqQlR`
};
module.exports = ___CSS_LOADER_EXPORT___;
