// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button-module__button___RPhop {
  cursor: pointer;
  padding: 10px 16px;
  border-style: solid;
  border-width: 1.5px;
}
.Button-module__button___RPhop:hover {
  filter: brightness(90%);
}
.Button-module__button___RPhop .Button-module__text___FkodM {
  text-wrap: nowrap;
}
.Button-module__button___RPhop.Button-module__loading___TmVeK {
  cursor: progress;
}`, "",{"version":3,"sources":["webpack://./../common/src/components/base/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,uBAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ","sourcesContent":[".button {\n  cursor: pointer;\n  padding: 10px 16px;\n  border-style: solid;\n  border-width: 1.5px;\n\n  &:hover {\n    filter: brightness(90%);\n  }\n\n  .text {\n    text-wrap: nowrap;\n  }\n\n  &.loading {\n    cursor: progress;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button-module__button___RPhop`,
	"text": `Button-module__text___FkodM`,
	"loading": `Button-module__loading___TmVeK`
};
module.exports = ___CSS_LOADER_EXPORT___;
