import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select, TextArea } from 'common-src/components/base';
import {
  AlcoholUse,
  EmploymentStatus,
  Housing,
  RecreationalDrugUse,
  Religion,
  TobaccoUse,
  updatePatient,
} from 'common-src/models/Patient';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import { getInitialData, getRequestBody } from './helpers';
import styles from './SocialHistory.module.scss';

const SocialHistory = ({ patientId, disabled }) => {
  const dispatch = useDispatch();

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));

  const [formData, setFormData] = useState({});

  const { showAlert, AlertType } = useAlert();

  if (!patient) return null;

  const {
    tobaccoUse,
    alcoholUse,
    recreationalDrugUse,
    employmentStatus,
    housing,
    religion,
    tobaccoAlcoholDrugNotes,
    employmentHousingNotes,
  } = patient;

  const { isEditMode, setIsEditMode, setIsLoading, renderContent, setInitialData } = useFormButtons(
    'section',
    setFormData,
    () => {
      setIsLoading(true);
      dispatch(
        updatePatient(patient.id, getRequestBody(formData), {
          successBlock: () => {
            setIsLoading(false);
            setIsEditMode(false);
            showAlert(
              AlertType.Success,
              '',
              'You have successfully updated patient social history.',
            );
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        }),
      );
    },
    formData,
    true,
    'social-history',
  );

  useEffect(() => {
    const initialData = getInitialData(
      tobaccoUse,
      alcoholUse,
      recreationalDrugUse,
      employmentStatus,
      housing,
      religion,
      tobaccoAlcoholDrugNotes,
      employmentHousingNotes,
    );

    setInitialData({ ...formData, ...initialData });
    setFormData((prev) => ({ ...prev, ...initialData }));
  }, [
    tobaccoUse,
    alcoholUse,
    recreationalDrugUse,
    employmentStatus,
    housing,
    religion,
    tobaccoAlcoholDrugNotes,
    employmentHousingNotes,
  ]);

  const onChangeHandler = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const renderForm = () => (
    <div className={styles.grid}>
      <Select
        id="tobacco-use"
        label="Tobacco/Nicotine"
        placeholder="Select"
        options={Object.values(TobaccoUse)}
        onChange={(op) => onChangeHandler('tobaccoUse', op)}
        value={formData.tobaccoUse}
        readOnly={!isEditMode}
        isClearable
        size="small"
      />
      <Select
        id="alcohol-use"
        label="Alcohol"
        placeholder="Select"
        options={Object.values(AlcoholUse)}
        onChange={(op) => onChangeHandler('alcoholUse', op)}
        value={formData.alcoholUse}
        readOnly={!isEditMode}
        isClearable
        size="small"
      />
      <Select
        id="recreational-drug-use"
        label="Recreational Drugs"
        placeholder="Select"
        options={Object.values(RecreationalDrugUse)}
        onChange={(op) => onChangeHandler('recreationalDrugUse', op)}
        value={formData.recreationalDrugUse}
        readOnly={!isEditMode}
        isClearable
        size="small"
      />
      <TextArea
        id="tobacco-alcohol-drug-notes"
        classNames={[styles.width100]}
        label="Additional Information"
        placeholder="Additional Information"
        maxRows={4}
        readOnly={!isEditMode}
        value={formData?.tobaccoAlcoholDrugNotes || ''}
        onTextChange={(value) => onChangeHandler('tobaccoAlcoholDrugNotes', value)}
        size="small"
      />
      <Select
        id="employment-status"
        label="Employment Status"
        placeholder="Select"
        options={Object.values(EmploymentStatus)}
        onChange={(op) => onChangeHandler('employmentStatus', op)}
        value={formData.employmentStatus}
        readOnly={!isEditMode}
        isClearable
        size="small"
      />
      <Select
        id="housing"
        label="Housing"
        placeholder="Select"
        options={Object.values(Housing)}
        onChange={(op) => onChangeHandler('housing', op)}
        value={formData.housing}
        readOnly={!isEditMode}
        isClearable
        size="small"
      />
      <Select
        id="religion"
        label="Religion"
        placeholder="Select"
        options={Object.values(Religion)}
        onChange={(op) => onChangeHandler('religion', op)}
        value={formData.religion}
        readOnly={!isEditMode}
        isClearable
        size="small"
      />
      <TextArea
        id="employment-housing-notes"
        classNames={[styles.width100]}
        label="Additional Information"
        placeholder="Additional Information"
        maxRows={4}
        readOnly={!isEditMode}
        value={formData?.employmentHousingNotes || ''}
        onTextChange={(value) => onChangeHandler('employmentHousingNotes', value)}
        size="small"
      />
    </div>
  );

  return (
    <>
      {renderForm()}
      {renderContent(true, disabled)}
    </>
  );
};

SocialHistory.propTypes = {
  patientId: PropTypes.number,
  disabled: PropTypes.bool,
};

export default SocialHistory;
