/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getIsOrganizationsCached } from 'common-src/features/cache';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { getPatientRestRequest } from 'common-src/models/Patient/actions';
import {
  getPatientInteractionAddendumsRequest,
  getPatientInteractionApiRequest,
} from 'common-src/models/PatientInteraction/actions';
import { getPatientLabReadingsRequest, VitalsLabsTypes } from 'common-src/models/PatientLabReading';
import { getPatientVitalsRequest } from 'common-src/models/PatientVital/actions';
import { getPatientWellnessPlansRequest } from 'common-src/models/PatientWellnessPlan';

import { getPatientNote } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

const useDocumentationRequests = (docId, addendaId, patientId) => {
  const dispatch = useDispatch();

  const [isRequestRunning, setIsRequestRunning] = useState(false);
  const [hasError, setHasError] = useState(false);

  const isOrganizationsCached = useCustomSelector((state) => getIsOrganizationsCached(state));
  const noteId = useCustomSelector((state) => getPatientNote(state, patientId));

  useEffect(() => {
    if (!docId || !patientId || !isOrganizationsCached) return;

    if (docId === noteId) return;

    const requests = [
      getPatientRestRequest(patientId),
      getPatientInteractionApiRequest(docId),
      getPatientInteractionAddendumsRequest(docId),
      getPatientVitalsRequest({ patientId, skip: 0, limit: 1, sort_order: 'desc' }),
      getPatientLabReadingsRequest({ patientId, typeIds: [VitalsLabsTypes.HbA1c.value] }),
      getPatientWellnessPlansRequest({ patientId }),
    ];

    if (addendaId) {
      requests.push(getPatientInteractionApiRequest(addendaId));
    }

    setIsRequestRunning(true);
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => setIsRequestRunning(false),
        errorBlock: () => setHasError(true),
      }),
    );
  }, [docId, patientId, addendaId, isOrganizationsCached]);

  return { isRequestRunning, hasError };
};

export default useDocumentationRequests;
