// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MedicationDetailsPopup-module__firstGrid___V_87O {
  display: grid;
  padding: 15px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}
.MedicationDetailsPopup-module__firstGrid___V_87O .MedicationDetailsPopup-module__width75___NDDzM {
  grid-column: 2 span;
}

.MedicationDetailsPopup-module__instructions___vbEBj {
  margin: 10px 0;
  padding: 15px 30px;
}

.MedicationDetailsPopup-module__notes___Lpvje {
  margin: 0;
  padding: 0 30px 10px 30px;
}

.MedicationDetailsPopup-module__secondGrid___rPEug {
  display: grid;
  padding: 15px 30px 32px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/popups/MedicationDetailsPopup/MedicationDetailsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,cAAA;EACA,kBAAA;AAAF;;AAGA;EACE,SAAA;EACA,yBAAA;AAAF;;AAGA;EACE,aAAA;EACA,4BAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AAAF","sourcesContent":[".firstGrid {\n  display: grid;\n  padding: 15px 30px;\n  grid-row-gap: 25px;\n  grid-column-gap: 25px;\n  grid-template-columns: repeat(3, 1fr);\n\n  .width75 {\n    grid-column: 2 span;\n  }\n}\n\n.instructions {\n  margin: 10px 0;\n  padding: 15px 30px;\n}\n\n.notes {\n  margin: 0;\n  padding: 0 30px 10px 30px;\n}\n\n.secondGrid {\n  display: grid;\n  padding: 15px 30px 32px 30px;\n  grid-row-gap: 25px;\n  grid-column-gap: 25px;\n  grid-template-columns: repeat(3, 1fr);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstGrid": `MedicationDetailsPopup-module__firstGrid___V_87O`,
	"width75": `MedicationDetailsPopup-module__width75___NDDzM`,
	"instructions": `MedicationDetailsPopup-module__instructions___vbEBj`,
	"notes": `MedicationDetailsPopup-module__notes___Lpvje`,
	"secondGrid": `MedicationDetailsPopup-module__secondGrid___rPEug`
};
module.exports = ___CSS_LOADER_EXPORT___;
