import moment from 'moment';

export const getDuration = (duration, filledDate, endDate) => {
  // Handle the case where a duration value is passed
  if (duration) {
    const match = duration.match(/(\d+)/);
    const value = match ? parseInt(match[0], 10) : null;

    const unitMatch = duration.match(/[a-zA-Z]/);
    const unit = unitMatch ? unitMatch[0] : null;

    let unitFullForm;
    switch (unit) {
      case 'd':
        unitFullForm = 'days';
        break;
      case 'm':
        unitFullForm = 'months';
        break;
      default:
        unitFullForm = '';
    }

    // Return the formatted string (e.g., '90 days' or '2 months')
    return `${value} ${unitFullForm}`;
  }

  if (filledDate && endDate) {
    const start = moment(filledDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');

    const differenceInDays = end.diff(start, 'days');
    return `${differenceInDays} days`;
  }

  return '-';
};
