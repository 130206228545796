/* eslint-disable global-require */
export const CommonIcons = {
  calendar: require('./icons/calendar.svg'),
  checkIcon: require('./icons/checkIcon.svg'),
  closeIcon: require('./icons/closeIcon.svg'),
  searchIcon: require('./icons/searchIcon.svg'),
  uncheckIcon: require('./icons/uncheckIcon.svg'),
  karetDownIcon: require('./icons/karetDownIcon.svg'),
  actions: require('./icons/actions.svg'),
  sortDownIcon: require('./icons/sortDownIcon.svg'),
  calendarCheckIcon: require('./icons/calendarCheckIcon.svg'),
  calendarXIcon: require('./icons/calendarXIcon.svg'),
  callCheckIcon: require('./icons/callCheckIcon.svg'),
  rescheduledIcon: require('./icons/rescheduledIcon.svg'),
  tableFilterSelected: require('./icons/tableFilterSelected.svg'),
  tableFilterUnselected: require('./icons/tableFilterUnselected.svg'),
  shippingIcon: require('./icons/shippingIcon.svg'),
  rightArrowIcon: require('./icons/rightArrowIcon.svg'),
  linkOutIcon: require('./icons/linkOutIcon.svg'),
  orderInProgressIcon: require('./icons/orderInProgressIcon.svg'),
  orderCompleted: require('./icons/orderCompleted.svg'),
  journeyIcon: require('./icons/journeyIcon.svg'),
  activeDevice: require('./icons/activeDevice.svg'),
  inactiveDevice: require('./icons/inactiveDevice.svg'),
  warningIcon: require('./icons/warningIcon.svg'),
  plusIcon: require('./icons/plusIcon.svg'),
  starIcon: require('./icons/starIcon.svg'),
  downIcon: require('./icons/downIcon.svg'),
  editIcon: require('./icons/editIcon.svg'),
  tooltipIcon: require('./icons/tooltipIcon.svg'),
  phoneCallIncon: require('./icons/phoneCallIncon.svg'),
  smsIcon: require('./icons/smsIcon.svg'),
  reminderIcon: require('./icons/reminderIcon.svg'),
  refreshIcon: require('./icons/refreshIcon.svg'),
  arrowDownIcon: require('./icons/arrowDownIcon.svg'),
  moreVertIcon: require('./icons/moreVertIcon.svg'),
  personIcon: require('./icons/personIcon.svg'),
  errorOutlineIcon: require('./icons/errorOutlineIcon.svg'),
  fishEyeIcon: require('./icons/fishEyeIcon.svg'),
  tonalityIcon: require('./icons/tonalityIcon.svg'),
  lensIcon: require('./icons/lensIcon.svg'),
  brightnessIcon: require('./icons/brightnessIcon.svg'),
  cancelIcon: require('./icons/cancelIcon.svg'),
  folderOpenIcon: require('./icons/folderOpenIcon.svg'),
  folderIcon: require('./icons/folderIcon.svg'),
  docIcon: require('./icons/docIcon.svg'),
  hamburgerIcon: require('./icons/hamburgerIcon.svg'),
  arrowLeftCircleIcon: require('./icons/arrowLeftCircleIcon.svg'),
  calendarCheckBoldIcon: require('./icons/calendarCheckBoldIcon.svg'),
  circleCheckIcon: require('./icons/circleCheckIcon.svg'),
  circleCloseIcon: require('./icons/circleCloseIcon.svg'),
  alertErrorIcon: require('./icons/alertErrorIcon.svg'),
  alertSuccessIcon: require('./icons/alertSuccessIcon.svg'),
  whiteCloseIcon: require('./icons/whiteCloseIcon.svg'),
  calendarNewIcon: require('./icons/calendarNewIcon.svg'),
  alertWarningIcon: require('./icons/alertWarningIcon.svg'),
};
