// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commonStyles-module__grid___cUZM9 {
  width: 100%;
  display: grid;
  column-gap: 16px;
  margin-bottom: 6px;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.4fr;
}
.commonStyles-module__grid___cUZM9 .commonStyles-module__width50___Ln2lY {
  grid-column: 2 span;
}

.commonStyles-module__button___AgTW5 {
  margin-bottom: 8px;
}
.commonStyles-module__button___AgTW5 > img {
  width: 18px;
  height: 18px;
}
.commonStyles-module__button___AgTW5 > span {
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Contacts/forms/commonStyles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,4CAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,0BAAA;AADJ","sourcesContent":[".grid {\n  width: 100%;\n  display: grid;\n  column-gap: 16px;\n  margin-bottom: 6px;\n  grid-template-columns: 1fr 1fr 1fr 1fr 0.4fr;\n\n  .width50 {\n    grid-column: 2 span;\n  }\n}\n\n.button {\n  margin-bottom: 8px;\n\n  & > img {\n    width: 18px;\n    height: 18px;\n  }\n\n  & > span {\n    font-size: 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `commonStyles-module__grid___cUZM9`,
	"width50": `commonStyles-module__width50___Ln2lY`,
	"button": `commonStyles-module__button___AgTW5`
};
module.exports = ___CSS_LOADER_EXPORT___;
