export const roles = {
  Admin: { id: 1, label: 'Admin' },
  Viewer: { id: 2, label: 'Viewer' },
  MXSpecialist: { id: 3, label: 'MX Specialist' },
  RN: { id: 4, label: 'RN' },
  CDE: { id: 5, label: 'CDE' },
  Physician: { id: 6, label: 'Physician' },
  ExternalPhysician: { id: 7, label: 'External Physician' },
  Enrollment: { id: 8, label: 'Enrollment' },
  AccountabilityCoach: { id: 9, label: 'Accountability Coach' },
  RD: { id: 10, label: 'RD' },
  NP: { id: 11, label: 'NP' },
  RCM: { id: 12, label: 'RCM' },
  System: { id: 13, label: 'System' },
  SocialWorker: { id: 14, label: 'Social Worker' },
  Neurologist: { id: 15, label: 'Neurologist' },
};
