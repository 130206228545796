// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EncounterPopup-module__grid___vTmHL {
  display: grid;
  padding: 25px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.EncounterPopup-module__grid___vTmHL .EncounterPopup-module__width100___gYVW2 {
  grid-column: 3 span;
}

.EncounterPopup-module__buttonsContainer___sBwg2 {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/EncounterPopup/EncounterPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,gDAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AAAF","sourcesContent":[".grid {\n  display: grid;\n  padding: 25px 30px;\n  grid-row-gap: 25px;\n  grid-column-gap: 25px;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n\n  .width100 {\n    grid-column: 3 span;\n  }\n}\n\n.buttonsContainer {\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n  justify-content: end;\n  padding: 30px;\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `EncounterPopup-module__grid___vTmHL`,
	"width100": `EncounterPopup-module__width100___gYVW2`,
	"buttonsContainer": `EncounterPopup-module__buttonsContainer___sBwg2`
};
module.exports = ___CSS_LOADER_EXPORT___;
