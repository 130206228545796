// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Addresses-module__grid___ix3Y3 {
  display: grid;
  row-gap: 16px;
  column-gap: 50px;
  margin: 16px 0px;
  grid-template-columns: repeat(3, 1fr);
}
.Addresses-module__grid___ix3Y3 .Addresses-module__width100___5aP4W {
  grid-column: 3 span;
}
.Addresses-module__grid___ix3Y3 .Addresses-module__checkbox___OLpJa {
  height: 25px;
  padding: 6px 10px;
}
.Addresses-module__grid___ix3Y3 .Addresses-module__checkbox___OLpJa.Addresses-module__edit___hjr24 {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/Addresses/Addresses.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,qCAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,YAAA;EACA,iBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN","sourcesContent":[".grid {\n  display: grid;\n  row-gap: 16px;\n  column-gap: 50px;\n  margin: 16px 0px;\n  grid-template-columns: repeat(3, 1fr);\n\n  .width100 {\n    grid-column: 3 span;\n  }\n\n  .checkbox {\n    height: 25px;\n    padding: 6px 10px;\n\n    &.edit {\n      margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `Addresses-module__grid___ix3Y3`,
	"width100": `Addresses-module__width100___5aP4W`,
	"checkbox": `Addresses-module__checkbox___OLpJa`,
	"edit": `Addresses-module__edit___hjr24`
};
module.exports = ___CSS_LOADER_EXPORT___;
