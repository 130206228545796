import PropTypes from 'prop-types';
import React from 'react';

import { moduleTypes } from 'common-src/models/PatientInteraction';

import useDocumentationConfig from 'src/hooks/useDocumentationConfig';
import useDocumentationErrors from 'src/hooks/useDocumentationErrors';
import useSaveDocumentation from 'src/hooks/useSaveDocumentation';
import {
  DocumentForm,
  SectionFooter,
  SectionHeader,
} from 'src/pages/DocumentationIndex/components';

import styles from '../commonStyles.module.scss';

const CloseAccountForm = ({ patientId, documentationId }) => {
  const { handleError, errors } = useDocumentationErrors();
  const { patient, documentationToEdit, templates, primaryModuleTypeId, setTemplates, serviceAt } =
    useDocumentationConfig(patientId, documentationId, moduleTypes.CloseRequest.id);
  const { savedAt, isDraftCompleting, isDraftSaving, setHasDetectedChanges, handleButtonClick } =
    useSaveDocumentation({
      handleError,
      templates,
      documentationToEdit,
      patientId,
      primaryModuleTypeId,
      serviceAt,
    });

  return (
    <>
      <SectionHeader patient={patient} />
      <section className={[styles.section, 'relative'].join(' ')}>
        <DocumentForm
          templates={templates}
          setTemplates={setTemplates}
          setHasDetectedChanges={setHasDetectedChanges}
          errors={errors}
          handleError={handleError}
        />
      </section>
      <SectionFooter
        isLoading={isDraftCompleting}
        isDraftSaving={isDraftSaving}
        draftSavedAt={savedAt}
        handleButtonClick={handleButtonClick}
      />
    </>
  );
};

CloseAccountForm.propTypes = {
  patientId: PropTypes.number,
  documentationId: PropTypes.string,
};

export default CloseAccountForm;
