import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew, Input, TextArea } from 'common-src/components/base';
import { getLastA1cReading } from 'common-src/models/PatientLabReading';
import { getLastPatientHeightAndWeight } from 'common-src/models/PatientVital';

import useCustomSelector from 'src/hooks/useCustomSelector';
import commonStyles from 'src/pages/DocumentationIndex/commonStyles.module.scss';

import { VitalsAndLabsFieldIds } from './constants';
import styles from './VitalsAndLabsModule.module.scss';

const VitalsAndLabsModule = ({
  patientId,
  vitalsAndLabs,
  setVitalsAndLabs,
  errors,
  setHasDetectedChanges,
  classNames = [],
}) => {
  const hasChanges = useRef(false);

  const lastA1c = useCustomSelector((state) => getLastA1cReading(state, patientId));
  const { height, weight } = useCustomSelector((state) =>
    getLastPatientHeightAndWeight(state, patientId),
  );

  useEffect(() => {
    if (hasChanges.current) return;

    if (_.isEmpty(vitalsAndLabs)) {
      setVitalsAndLabs((prev) => ({
        ...prev,
        height: height?.height || null,
        weight: weight?.weight || null,
        weightDateObserved: weight?.dateObserved || height?.dateObserved || null,
        hbA1c: lastA1c?.readingValue || null,
        hbA1cDateObserved: lastA1c?.readingDate || null,
        additionalDetails: '',
      }));
      return;
    }

    const newData = { ...vitalsAndLabs };
    if (weight && moment(vitalsAndLabs.weightDateObserved).isBefore(weight.dateObserved)) {
      Object.assign(newData, {
        height: height?.height || vitalsAndLabs.height || null,
        weight: weight.weight,
        weightDateObserved: weight.dateObserved,
      });
    }

    if (lastA1c && moment(vitalsAndLabs.hbA1cDateObserved).isBefore(lastA1c.readingDate)) {
      Object.assign(newData, {
        hbA1c: lastA1c.readingValue,
        hbA1cDateObserved: lastA1c.readingDate,
      });
    }

    setVitalsAndLabs(newData);
  }, [height, weight, lastA1c]);

  const onChange = (field, value) => {
    setVitalsAndLabs((prev) => ({ ...prev, [field]: value }));
    hasChanges.current = true;
    setHasDetectedChanges(new Date());
  };

  return (
    <div className={[styles.container, ...classNames].join(' ')}>
      <span className={['font-w-600', 'font-s-16'].join(' ')}>Vitals & Labs</span>
      <span className={['font-w-500', 'font-s-14'].join(' ')}>
        Review the patient’s most recent height, weight, and A1C below. Record any changes reported.
      </span>
      <div className="flex-row gap-24">
        <Input
          id={VitalsAndLabsFieldIds.height}
          classNames={[styles.width50]}
          inputClassNames={[commonStyles.inputNumber]}
          type="number"
          label="Height (in)"
          description="Verify height"
          placeholder="Height (in)"
          value={vitalsAndLabs.height || ''}
          onFinishEditing={(value) => onChange('height', value || null)}
          debounced
          isFloat
        />
        <span className={styles.width50} />
      </div>
      <div className="flex-row gap-24">
        <Input
          id={VitalsAndLabsFieldIds.weight}
          classNames={[styles.width50]}
          inputClassNames={[commonStyles.inputNumber]}
          type="number"
          label="Weight (lbs)"
          description="Enter most recent weight if known"
          placeholder="Weight (lbs)"
          value={vitalsAndLabs.weight || ''}
          onFinishEditing={(value) => onChange('weight', value || null)}
          debounced
          isFloat
        />
        <DatePickerNew
          containerId={VitalsAndLabsFieldIds.weightDateContainer}
          id={VitalsAndLabsFieldIds.weightDate}
          classNames={[styles.width50, commonStyles.date]}
          inputClassNames={[commonStyles.inputDateContainer]}
          textClassNames={[commonStyles.dateInput]}
          header="Date observed"
          placeholder="Date observed"
          description="Date weight was last observed"
          value={vitalsAndLabs.weightDateObserved}
          iconSrc={CommonIcons.calendarCheckBoldIcon}
          onDateSelected={(date) => {
            const dateToApply = date ? moment(date).format('YYYY-MM-DD') : null;
            onChange('weightDateObserved', dateToApply);
          }}
          required={!!vitalsAndLabs.weight || !!vitalsAndLabs.height}
          errorText={errors.get(VitalsAndLabsFieldIds.weightDateContainer)}
        />
      </div>
      <div className="flex-row gap-24">
        <Input
          containerId={VitalsAndLabsFieldIds.hbA1cContainer}
          id={VitalsAndLabsFieldIds.hbA1c}
          classNames={[styles.width50]}
          inputClassNames={[commonStyles.inputNumber]}
          type="number"
          label="HbA1c"
          description="Enter most recent HbA1c if known"
          value={vitalsAndLabs.hbA1c || ''}
          onFinishEditing={(value) => onChange('hbA1c', value || null)}
          debounced
          isFloat
          errorText={errors.get(VitalsAndLabsFieldIds.hbA1cContainer)}
          decimalPlaceCount={2}
        />
        <DatePickerNew
          containerId={VitalsAndLabsFieldIds.hbA1cDateContainer}
          id={VitalsAndLabsFieldIds.hbA1cDate}
          classNames={[styles.width50, commonStyles.date]}
          inputClassNames={[commonStyles.inputDateContainer]}
          textClassNames={[commonStyles.dateInput]}
          header="Date observed"
          placeholder="Date observed"
          description="Approximate date of HbA1c"
          value={vitalsAndLabs.hbA1cDateObserved}
          iconSrc={CommonIcons.calendarCheckBoldIcon}
          onDateSelected={(date) => {
            const dateToApply = date ? moment(date).format('YYYY-MM-DD') : null;
            onChange('hbA1cDateObserved', dateToApply);
          }}
          required={!!vitalsAndLabs.hbA1c}
          errorText={errors.get(VitalsAndLabsFieldIds.hbA1cDateContainer)}
        />
      </div>
      <TextArea
        id={VitalsAndLabsFieldIds.additionalDetails}
        inputClassNames={[commonStyles.inputText]}
        label="Additional details"
        placeholder="Up to 255 characters"
        value={vitalsAndLabs.additionalDetails}
        maxRows={8}
        fixedRows={3}
        onFinishEditing={(value) => onChange('additionalDetails', value)}
        debounced
        withError={false}
      />
    </div>
  );
};

VitalsAndLabsModule.propTypes = {
  patientId: PropTypes.number,
  vitalsAndLabs: PropTypes.object,
  setVitalsAndLabs: PropTypes.func,
  errors: PropTypes.object,
  setHasDetectedChanges: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default VitalsAndLabsModule;
