import PropTypes from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';

import Patient from 'common-src/models/Patient';
import { getSectionName, moduleTypes } from 'common-src/models/PatientInteraction';
import { getAllowedDocumentationModules } from 'common-src/models/PatientInteraction/helpers';

import useCustomSelector from 'src/hooks/useCustomSelector';
import { excludedModulesIds } from 'src/pages/DocumentationIndex/constants';

import { ModuleItem } from './items';
import styles from './SectionAside.module.scss';

const SectionAside = ({ onTemplateSelect, patient, templates }) => {
  const [selectedLink, setSelectedLink] = useState({});

  const allowedModules = useCustomSelector((state) => getAllowedDocumentationModules(state));

  const excludedIds = useMemo(() => {
    const ids = excludedModulesIds;

    if (!patient.mfaApprovedAt) {
      if (!ids.includes(moduleTypes.MFARenewalRequest.id)) {
        ids.push(moduleTypes.MFARenewalRequest.id);
      }
    } else {
      const mfaRenewalRequestIndex = ids.indexOf(moduleTypes.MFARenewalRequest.id);
      if (mfaRenewalRequestIndex > -1) {
        ids.splice(mfaRenewalRequestIndex, 1);
      }
    }

    return ids;
  }, [patient.mfaApprovedAt]);

  const renderModules = () =>
    Object.entries(allowedModules).map(([section, moduleIds]) => {
      if (moduleIds.size === 0) return;

      return (
        <Fragment key={section}>
          <p
            className={[styles.header, 'font-w-600', 'p-l-20', 'font-s-18'].join(' ')}
          >{`${getSectionName(section)} Documents`}</p>
          {Array.from(moduleIds)
            .filter((moduleId) => !excludedIds.includes(moduleId))
            .sort((a, b) => {
              const aTitle = Object.values(moduleTypes).find(
                (moduleType) => moduleType.id === a,
              ).title;
              const bTitle = Object.values(moduleTypes).find(
                (moduleType) => moduleType.id === b,
              ).title;

              return aTitle.localeCompare(bTitle);
            })
            .map((moduleId) => (
              <ModuleItem
                key={moduleId}
                moduleId={moduleId}
                setSelectedLink={setSelectedLink}
                selectedLink={selectedLink}
                onChangeHandler={() => {
                  onTemplateSelect(moduleId);
                  if (selectedLink[moduleId]) {
                    setSelectedLink({});
                  }
                }}
                template={templates.find((template) => template.templateType === moduleId)}
              />
            ))}
        </Fragment>
      );
    });

  return (
    <aside className={[styles.aside, 'p-t-30', 'primary-border-r'].join(' ')}>
      <p className={[styles.description, 'font-s-14'].join(' ')}>
        Select relevant form templates from this menu, then record your responses.
      </p>
      {renderModules()}
    </aside>
  );
};

SectionAside.propTypes = {
  onTemplateSelect: PropTypes.func,
  patient: PropTypes.exact(Patient.schema),
  templates: PropTypes.arrayOf(PropTypes.object),
};

export default SectionAside;
