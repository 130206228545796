export const TemplateItemType = Object.freeze({
  Heading: 'Heading',
  Question: 'Question',
  Module: 'Module',
});

export const TemplateQuestionType = Object.freeze({
  Text: 'text',
  TextArea: 'textArea',
  Integer: 'integer',
  Currency: 'currency',
  Number: 'number',
  Checkbox: 'checkbox',
  Radio: 'radio',
  Date: 'date',
  MultiSelect: 'multiSelect',
});

export const TemplateModuleType = Object.freeze({
  Sdoh: 'Sdoh',
  CGMAlerts: 'CgmAlerts',
  CGMEvaluation: 'CgmEvaluation',
  VitalsAndLabs: 'VitalsAndLabs',
  WellnessPlan: 'WellnessPlan',
});

export const TemplateDisplayConditionType = Object.freeze({
  And: 'And',
  Or: 'Or',
  Equals: 'Equals',
  Selected: 'Selected',
});
