import { LOGOUT_COMPLETE } from 'common-src/features/auth/actionTypes';
import { createReducer } from 'common-src/utils/reducerUtils';

import {
  CLOSE_PATIENT_DETAILS,
  RESET_DATA_ON_CLOSE,
  RESET_TAB_INFO,
  RESET_TABS_INFO,
  SAVE_DASHBOARD_FILTERS,
  SAVE_IS_CURIE_AI_SELECTED,
  SAVE_IS_DRAFT_DELETING,
  SAVE_IS_DRAFT_SAVING,
  SAVE_IS_PATIENT_SIDEBAR_EXTENDED,
  SAVE_NOTE_DATA,
  SAVE_NOTE_SCROLL,
  SAVE_PATIENT_DETAILS_TAB_INFO,
  SAVE_PATIENT_DOCUMENTATION_TAB_INFO,
  SAVE_PATIENT_LAST_MEDICATIONS_PULL,
  SAVE_PATIENT_MAIN_TAB,
  SAVE_PATIENT_NOTE,
  SAVE_PATIENT_TIMELINE_TAB_INFO,
  SAVE_SETTINGS_TAB_INFO,
  SET_ACTIVE_TABS,
} from './actionTypes';
import { TabState } from './constants';

const initialState = {
  [TabState.PATIENT_DETAILS.name]: TabState.PATIENT_DETAILS.initialState,
  [TabState.SETTINGS.name]: TabState.SETTINGS.initialState,
  [TabState.DASHBOARD.name]: TabState.DASHBOARD.initialState,
  activeTabs: [],
};

const updateTabInfo = (state, tab, updatedState) => {
  const { shouldResetData } = state[tab.name];
  const newTabState = shouldResetData ? tab.initialState : { ...state[tab.name], ...updatedState };

  return {
    ...state,
    [tab.name]: newTabState,
  };
};

const entityHandlers = {
  [SAVE_PATIENT_DETAILS_TAB_INFO]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        path: payload.path,
      },
    },
  }),
  [SAVE_PATIENT_DOCUMENTATION_TAB_INFO]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        documentation: {
          ...payload.tabInfo,
        },
      },
    },
  }),
  [SAVE_PATIENT_TIMELINE_TAB_INFO]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        timeline: {
          ...payload.tabInfo,
        },
      },
    },
  }),
  [SAVE_IS_PATIENT_SIDEBAR_EXTENDED]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        isExtended: payload.isExtended,
      },
    },
  }),
  [SAVE_PATIENT_LAST_MEDICATIONS_PULL]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        lastMedicationsPull: payload.lastPullAt,
      },
    },
  }),
  [SAVE_PATIENT_MAIN_TAB]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        mainTab: payload.mainTab,
      },
    },
  }),
  [CLOSE_PATIENT_DETAILS]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {},
    },
  }),
  [SAVE_SETTINGS_TAB_INFO]: (state, payload) => {
    const updatedState = { lastPath: payload.lastPath };
    return updateTabInfo(state, TabState.SETTINGS, updatedState);
  },
  [RESET_TAB_INFO]: (state, payload) => ({
    ...state,
    [payload.tab.name]: payload.tab.initialState,
  }),
  [RESET_TABS_INFO]: (state, payload) => ({
    ...state,
    ...payload.tabs,
  }),
  [RESET_DATA_ON_CLOSE]: (state, payload) => ({
    ...state,
    [payload.tab.name]: { ...state[payload.tab.name], shouldResetData: true },
  }),
  [SET_ACTIVE_TABS]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [SAVE_PATIENT_NOTE]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        docId: payload.docId,
      },
    },
  }),
  [SAVE_NOTE_DATA]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        noteData: payload.noteData,
      },
    },
  }),
  [SAVE_NOTE_SCROLL]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        noteScroll: payload.scroll,
      },
    },
  }),
  [SAVE_IS_DRAFT_SAVING]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        isDraftSaving: payload.isDraftSaving,
      },
    },
  }),
  [SAVE_IS_DRAFT_DELETING]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        isDraftDeleting: payload.isDraftDeleting,
      },
    },
  }),
  [SAVE_DASHBOARD_FILTERS]: (state, payload) => {
    const updatedState = { filters: payload.filters };
    return updateTabInfo(state, TabState.DASHBOARD, updatedState);
  },
  [SAVE_IS_CURIE_AI_SELECTED]: (state, payload) => ({
    ...state,
    [TabState.PATIENT_DETAILS.name]: {
      ...state[TabState.PATIENT_DETAILS.name],
      [payload.patientId]: {
        ...state[TabState.PATIENT_DETAILS.name][payload.patientId],
        isCurieAISelected: payload.isSelected,
      },
    },
  }),
  [LOGOUT_COMPLETE]: () => initialState,
};

export const reducer = createReducer(initialState, entityHandlers);
