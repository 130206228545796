import { attr, fk, Model } from 'redux-orm';

class Template extends Model {
  static modelName = 'Template';

  static fields = {
    id: attr(),
    title: attr(),
    templateType: attr(),
    instructions: attr(),
    version: attr(),
    items: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'templateNewCreator'),
    updatedBy: fk('Client', 'templateNewChanger'),
  };
}

export default Template;
