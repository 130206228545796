import _ from 'lodash';

import Config from 'common-src/config';
import { logOutRequest } from 'common-src/features/auth/actions';
import { setOrm } from 'common-src/features/orm';
import orm from 'common-src/orm';
import { uuidv4 } from 'common-src/utils/stringUtils';

import { store } from 'src/store';

import { HttpMethods } from './constants';

export class RestApiError extends Error {
  constructor(message, body, status) {
    super(message);
    this.body = body;
    this.name = 'RestApiError';
    this.status = status;
  }
}

export const abortControllers = new Map();

export const apiRequest = ({
  endpoint,
  method = HttpMethods.Get,
  queryParams = {},
  body = {},
  useWebsocketUrl = false,
}) => {
  const protocol = Config.API_PROTO || 'https';
  const address = useWebsocketUrl ? Config.WEBSOCKET_URL : Config.API_URL;
  const url = new URL(`${protocol}://${address}/${endpoint}`);

  const reqId = uuidv4();
  const controller = new AbortController();
  abortControllers.set(reqId, controller);

  if (method === HttpMethods.Get) {
    if (queryParams) {
      const cleanedQueryParams = _.pickBy(queryParams, (v) => v !== undefined);
      url.search = new URLSearchParams(cleanedQueryParams).toString();
    }
    return fetch(url, {
      method: HttpMethods.Get,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      signal: controller.signal,
    })
      .then((res) => {
        if (res.status === 401) {
          abortControllers.forEach((c) => c.abort());
          store.dispatch(logOutRequest({ isSessionExpired: true }));
        }

        return res;
      })
      .finally(() => abortControllers.delete(reqId));
  }

  return fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'include',
    signal: controller.signal,
  })
    .then((res) => res)
    .finally(() => abortControllers.delete(reqId));
};

export const deleteOrmItem = (modelName, id) => {
  const { orm: rootOrm } = store.getState();
  const session = orm.session(rootOrm);
  const modelClass = session[modelName];
  const foundModelById = modelClass.withId(id);

  if (!foundModelById || typeof foundModelById.delete !== 'function') return;

  foundModelById.delete();
  store.dispatch(setOrm(session.state));
};

export const deleteOrmItems = (modelName, filterFn = () => true) => {
  const { orm: rootOrm } = store.getState();
  const session = orm.session(rootOrm);
  const modelClass = session[modelName];

  modelClass.filter(filterFn).all().delete();
  store.dispatch(setOrm(session.state));
};

const getErrorDetailMessage = (errorDetail) => {
  if (typeof errorDetail === 'string') {
    return errorDetail;
  }

  if (typeof errorDetail === 'object') {
    const detail = errorDetail?.body?.detail;

    if (typeof detail === 'string') {
      return errorDetail;
    }

    if (Array.isArray(detail)) {
      return detail[0]?.msg || '';
    }
  }

  if (Array.isArray(errorDetail)) {
    const err = errorDetail[0];
    if (err) {
      return `${err.type?.split('.')?.[0]}: ${err.msg}, (${err.loc?.[1]})`;
    }
  }
};

export const getRestErrorMessage = (error) => {
  const userMessage = error?.user_message;
  const errorDetail = error?.detail;

  return userMessage || getErrorDetailMessage(errorDetail) || '';
};
