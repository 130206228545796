// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MaxTabLimitPopup-module__container___i7Bzb {
  min-width: 700px;
  padding: 0 25px;
  margin-top: 30px;
}
.MaxTabLimitPopup-module__container___i7Bzb .MaxTabLimitPopup-module__buttonsContainer___IVlyl {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 10px 0 20px 0;
}
.MaxTabLimitPopup-module__container___i7Bzb .MaxTabLimitPopup-module__buttonsContainer___IVlyl .MaxTabLimitPopup-module__button___s7NFr {
  margin: 10px 0;
  width: 50%;
}
.MaxTabLimitPopup-module__container___i7Bzb .MaxTabLimitPopup-module__buttonsContainer___IVlyl .MaxTabLimitPopup-module__button___s7NFr:last-child {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/popups/MaxTabLimitPopup/MaxTabLimitPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,OAAA;EACA,uBAAA;EACA,sBAAA;AACJ;AACI;EACE,cAAA;EACA,UAAA;AACN;AACM;EACE,iBAAA;AACR","sourcesContent":[".container {\n  min-width: 700px;\n  padding: 0 25px;\n  margin-top: 30px;\n\n  .buttonsContainer {\n    display: flex;\n    flex-direction: row;\n    flex: 1;\n    justify-content: center;\n    padding: 10px 0 20px 0;\n\n    .button {\n      margin: 10px 0;\n      width: 50%;\n\n      &:last-child {\n        margin-left: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MaxTabLimitPopup-module__container___i7Bzb`,
	"buttonsContainer": `MaxTabLimitPopup-module__buttonsContainer___IVlyl`,
	"button": `MaxTabLimitPopup-module__button___s7NFr`
};
module.exports = ___CSS_LOADER_EXPORT___;
