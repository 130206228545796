// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupplementPopup-module__grid___v159M {
  display: grid;
  padding: 25px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}
.SupplementPopup-module__grid___v159M .SupplementPopup-module__width75___mQuV7 {
  grid-column: 2 span;
}
.SupplementPopup-module__grid___v159M .SupplementPopup-module__width100___RDQdQ {
  grid-column: 3 span;
}

.SupplementPopup-module__buttonsContainer___hqN0h {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/SupplementPopup/SupplementPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AADF","sourcesContent":[".grid {\n  display: grid;\n  padding: 25px 30px;\n  grid-row-gap: 25px;\n  grid-column-gap: 25px;\n  grid-template-columns: repeat(3, 1fr);\n\n  .width75 {\n    grid-column: 2 span;\n  }\n\n  .width100 {\n    grid-column: 3 span;\n  }\n}\n\n.buttonsContainer {\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n  justify-content: end;\n  padding: 30px;\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `SupplementPopup-module__grid___v159M`,
	"width75": `SupplementPopup-module__width75___mQuV7`,
	"width100": `SupplementPopup-module__width100___RDQdQ`,
	"buttonsContainer": `SupplementPopup-module__buttonsContainer___hqN0h`
};
module.exports = ___CSS_LOADER_EXPORT___;
