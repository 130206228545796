import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { TextArea } from 'common-src/components/base';
import { updateWellnessPlan } from 'common-src/models/PatientWellnessPlan';

import useAlert from 'src/hooks/useAlert';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from './AccordionItem.module.scss';

const AccordionItem = ({ formField, wellnessPlanId, title = '', value = '' }) => {
  const dispatch = useDispatch();

  const [isExtended, setIsExtended] = useState(true);
  const [formData, setFormData] = useState('');

  const { showAlert, AlertType } = useAlert();
  const { isEditMode, setIsEditMode, setIsLoading, renderContent, setInitialData } = useFormButtons(
    'Section',
    setFormData,
    () => {
      setIsLoading(true);
      dispatch(
        updateWellnessPlan(
          wellnessPlanId,
          { [formField]: formData },
          {
            successBlock: () => {
              setIsLoading(false);
              setIsEditMode(false);
              showAlert(AlertType.Success, '', `You have successfully updated ${title}.`);
            },
            errorBlock: (err) => {
              setIsLoading(false);
              showAlert(AlertType.Error, '', err);
            },
          },
        ),
      );
    },
    formData,
    true,
    title.toLowerCase(),
  );

  useEffect(() => {
    setInitialData(value);
    setFormData(value);
  }, [value]);

  const renderForm = () => {
    if (!isEditMode)
      return (
        <p className={[styles.readOnly, 'font-s-14', 'font-w-500', 'm-b-10', 'm-l-10'].join(' ')}>
          {value || 'Not yet established'}
        </p>
      );

    return (
      <TextArea
        id={title.toLowerCase()}
        classNames={[styles.textAreaContainer, 'm-b-16']}
        inputClassNames={[styles.textArea]}
        onTextChange={(value) => setFormData(value)}
        value={formData ?? ''}
        placeholder={`Enter ${title}`}
        maxRows={20}
        readOnly={!isEditMode}
        size="small"
        withError={false}
      />
    );
  };

  return (
    <div className={[styles.container, 'primary-border'].join(' ')}>
      <div
        className={[
          'flex-row',
          'vertically-centered',
          'gap-16',
          'cursor-pointer',
          'p-16',
          'disable-select',
        ].join(' ')}
        onClick={() => setIsExtended((prev) => !prev)}
        role="presentation"
      >
        <img
          src={CommonIcons.arrowDownIcon}
          alt="arrow-down-icon"
          className={[styles.arrow, isExtended ? styles.rotate : ''].join(' ')}
        />
        <p
          id={`accordion-title-${title.toLowerCase()}`}
          className={['font-w-700', 'font-s-16'].join(' ')}
        >
          {title}
        </p>
      </div>
      {isExtended && (
        <div className={[styles.content, 'primary-border-t'].join(' ')}>
          {renderForm()}
          {renderContent()}
        </div>
      )}
    </div>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string,
  formField: PropTypes.string,
  value: PropTypes.string,
  wellnessPlanId: PropTypes.string,
};

export default AccordionItem;
