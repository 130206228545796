import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { CustomCheckbox, DatePickerNew, Input } from 'common-src/components/base';
import { apiRequest, getRestErrorMessage, HttpMethods } from 'common-src/features/rest';

import { AddButton } from 'src/components/buttons';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import { downLoadPDF, getIsButtonEnabled, getPayload, validateData } from './helpers';
import styles from './SendDocumentationPopup.module.scss';

const SendDocumentationPopup = ({ patientId, onClose }) => {
  const [data, setData] = useState(initialState);
  const [showEmail, setShowEmail] = useState(false);

  const { errors, setErrors, renderButtons } = usePopup();

  const onChangeHandler = (key, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const newData = _.set(dataCopy, key, value);
    setData(newData);

    setErrors((prev) => ({ ...prev, ...validateData(key, value, newData) }));
  };

  const onDateChangeHandler = (key, keyToCompare, isBefore, value) => {
    const newData = { ...data, [key]: value };
    const err = { ...validateData(key, value, newData) };

    const compareCondition = isBefore
      ? moment(value).isAfter(moment(newData?.[keyToCompare]))
      : moment(value).isBefore(moment(newData?.[keyToCompare]));

    if (!compareCondition) {
      if (newData[key]) {
        Object.assign(err, { ...validateData(key, value, newData) });
      }
      if (newData[keyToCompare]) {
        Object.assign(err, { ...validateData(keyToCompare, newData?.[keyToCompare], newData) });
      }
    }

    setErrors((prev) => ({ ...prev, ...err }));
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();
    apiRequest({
      endpoint: `members/${patientId}/generateDocumentation`,
      queryParams: {},
      body: getPayload(data),
      method: HttpMethods.Post,
    }).then(async (res) => {
      if (!res.ok) {
        const err = await res.json();
        const errMsg = getRestErrorMessage(err) || '';
        errorCallback(errMsg);
        return;
      }

      if (!data.email) {
        downLoadPDF(res, data, patientId);
      }

      successCallback('Documentation generated!');
      onClose(true);
    });
  };

  const renderEmail = () => {
    if (showEmail) {
      return (
        <div className={['flex-row', 'vertically-centered', 'gap-10'].join(' ')}>
          <Input
            id="email"
            classNames={['flex-1']}
            label="Email address"
            placeholder="Enter an email address"
            onTextChange={(text) => onChangeHandler('email', text)}
            value={data.email}
            errorText={errors.email}
            required
          />
          <img
            className="icon-btn"
            src={CommonIcons.closeIcon}
            alt="reset-icon"
            role="presentation"
            onClick={() => {
              setShowEmail(false);
              onChangeHandler('email', '');
            }}
          />
        </div>
      );
    }

    return (
      <AddButton
        id="show-email-input"
        label="Send email instead of download"
        onClick={() => setShowEmail(true)}
        classNames={['m-b-20']}
      />
    );
  };

  return (
    <BasePopup id="generate-documentation" open onClose={onClose} title="Generate Documentation">
      <div className={['p-l-30', 'p-r-30', 'p-t-20', 'p-b-5'].join(' ')}>
        <p className={[styles.label, 'font-w-500', 'font-s-14', 'm-b-8'].join(' ')}>
          Type(s) of Documentation*
        </p>
        <div className={[styles.grid].join(' ')}>
          <CustomCheckbox
            id="chart-notes"
            header="Chart Notes"
            checked={data.types.chartNotes}
            onChange={(value) => onChangeHandler('types.chartNotes', value)}
          />
          <CustomCheckbox
            id="bgm-reading-logs"
            header="Reading Logs (BGM)"
            checked={data.types.bgmReadingLogs}
            onChange={(value) => onChangeHandler('types.bgmReadingLogs', value)}
          />
          <CustomCheckbox
            id="cgm-reading-logs"
            header="Reading Logs (CGM)"
            checked={data.types.cgmReadingLogs}
            onChange={(value) => onChangeHandler('types.cgmReadingLogs', value)}
          />
          <CustomCheckbox
            id="orders-and-consent"
            header="Orders & Informed Consent"
            checked={data.types.ordersAndConsent}
            onChange={(value) => onChangeHandler('types.ordersAndConsent', value)}
          />
          <CustomCheckbox
            id="rpm-claim-documentation"
            header="RPM Claim Documentation"
            checked={data.types.rpmClaimDocumentation}
            onChange={(value) => onChangeHandler('types.rpmClaimDocumentation', value)}
          />
          <CustomCheckbox
            id="e/m-claim-documentation"
            header="E/M Claim Documentation"
            checked={data.types.emClaimDocumentation}
            onChange={(value) => onChangeHandler('types.emClaimDocumentation', value)}
          />
          <CustomCheckbox
            id="hawb-claim-documentation"
            header="HAWB Claim Documentation"
            checked={data.types.hawbClaimDocumentation}
            onChange={(value) => onChangeHandler('types.hawbClaimDocumentation', value)}
          />
          <CustomCheckbox
            id="ccm-claim-documentation"
            header="CCM Claim Documentation"
            checked={data.types.ccmClaimDocumentation}
            onChange={(value) => onChangeHandler('types.ccmClaimDocumentation', value)}
          />
        </div>
        <p className={[styles.error, 'font-s-12', 'm-t-5', 'm-b-30', 'm-l-8'].join(' ')}>
          {errors.types}
        </p>
        <div className={['flex-row', 'gap-40', 'm-b-20'].join(' ')}>
          <DatePickerNew
            id="start-date"
            header="Start date"
            placeholder="Select date"
            maxDate={new Date()}
            value={data.startDate}
            onDateSelected={(date) =>
              onDateChangeHandler(
                'startDate',
                'endDate',
                true,
                date ? moment(date).format('YYYY-MM-DD') : null,
              )
            }
            onBlur={(date) =>
              setErrors((prev) => ({ ...prev, ...validateData('startDate', date, data) }))
            }
            errorText={errors.startDate}
            required
          />
          <DatePickerNew
            id="end-date"
            header="End date"
            placeholder="Select date"
            maxDate={new Date()}
            value={data.endDate}
            onDateSelected={(date) =>
              onDateChangeHandler(
                'endDate',
                'startDate',
                false,
                date ? moment(date).format('YYYY-MM-DD') : null,
              )
            }
            onBlur={(date) =>
              setErrors((prev) => ({ ...prev, ...validateData('endDate', date, data) }))
            }
            errorText={errors.endDate}
            required
          />
        </div>
        {renderEmail()}
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        submitButtonText: showEmail ? 'Send and Close' : 'Download and Close',
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, showEmail),
      })}
    </BasePopup>
  );
};

SendDocumentationPopup.propTypes = {
  onClose: PropTypes.func,
  patientId: PropTypes.number,
};

export default SendDocumentationPopup;
