// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header-module__container___mt0nn {
  display: flex;
  padding: 16px 32px;
  align-items: start;
  justify-content: space-between;
}
.Header-module__container___mt0nn .Header-module__button___izwC0 {
  display: flex;
  height: 38px;
  width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Labs/headers/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,YAAA;EACA,YAAA;AACJ","sourcesContent":[".container {\n  display: flex;\n  padding: 16px 32px;\n  align-items: start;\n  justify-content: space-between;\n\n  .button {\n    display: flex;\n    height: 38px;\n    width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Header-module__container___mt0nn`,
	"button": `Header-module__button___izwC0`
};
module.exports = ___CSS_LOADER_EXPORT___;
