import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import { getCurrentRoleId } from 'common-src/features/auth';
import { roles } from 'common-src/models/Client/constants';
import { getPatientCoveragesRequest } from 'common-src/models/PatientCoverage';
import {
  ACTIVE_STATUSES,
  getPatientPreAuths,
  getPreAuthServiceType,
  getPreAuthsRequest,
  getPreAuthStatus,
  PreAuthStatus,
} from 'common-src/models/PreAuthorization';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { BaseTable, TablePagination, TextItem } from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, PreAuthsTabs, TableHeaders } from './constants';
import { getTabInfo } from './helpers';
import styles from './PreAuths.module.scss';

const PreAuths = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(PreAuthsTabs);

  // state
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [filteredAuths, setFilteredAuths] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  // redux
  const auths = useCustomSelector((state) => getPatientPreAuths(state, patientId));
  const roleId = useCustomSelector((state) => getCurrentRoleId(state));

  const canUpdateSubmittedStatus = useMemo(
    () => [roles.RCM.id, roles.Admin.id, roles.Neurologist.id].includes(roleId),
    [roleId],
  );

  const { isRequesting } = useRequestLoading([
    getPreAuthsRequest(
      { patientId },
      { successBlock: () => dispatch(getPatientCoveragesRequest({ patientId })) },
    ),
  ]);

  useInterval(() => {
    dispatch(getPreAuthsRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, allAuths, activeAuths, inactiveAuths } = getTabInfo(auths);
    setTabInfo(tabInfo);

    let authsToShow;
    switch (selectedTab) {
      case tabs[0]:
        authsToShow = activeAuths;
        break;
      case tabs[1]:
        authsToShow = inactiveAuths;
        break;
      case tabs[2]:
        authsToShow = allAuths;
        break;
      default:
        authsToShow = [];
    }

    setFilteredAuths(authsToShow);
  }, [selectedTab, auths]);

  const renderStatus = (status) => {
    const data = getPreAuthStatus(status);
    return <StatusItem isActive withArrow={false} text={data.label} backgroundColor={data.color} />;
  };

  const getIsUnderlined = (auth) => {
    if (auth.status === PreAuthStatus.Submitted.value && !canUpdateSubmittedStatus) return false;

    return ACTIVE_STATUSES.includes(auth.status) && actionsAllowed;
  };

  const getData = () =>
    filteredAuths.map((auth) => ({
      planName: (
        <TextItem
          isActive
          text={auth?.patientCoverage?.name}
          onClick={() => {
            if (!ACTIVE_STATUSES.includes(auth.status) || !actionsAllowed) return;
            if (auth.status === PreAuthStatus.Submitted.value && !canUpdateSubmittedStatus) return;

            dispatch(openModal(ModalType.PRE_AUTH, { patientId, auth }));
          }}
          classNames={[styles.tableCell]}
          isUnderlined={getIsUnderlined(auth)}
          disableTooltip
        />
      ),
      authCode: <TextItem isActive text={auth.authCode || '-'} disableTooltip />,
      serviceType: (
        <TextItem isActive text={getPreAuthServiceType(auth.serviceType)} disableTooltip />
      ),
      status: renderStatus(auth.status),
      startDate: (
        <TextItem
          isActive
          text={auth.startDate ? moment(auth.startDate).format('MM/DD/YYYY') : '-'}
          value={auth.startDate}
          disableTooltip
        />
      ),
      endDate: (
        <TextItem
          isActive
          text={auth.endDate ? moment(auth.endDate).format('MM/DD/YYYY') : '-'}
          value={auth.endDate}
          disableTooltip
        />
      ),
    }));

  const showPreAuthPopup = () => dispatch(openModal(ModalType.PRE_AUTH, { patientId }));

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling pre authorizations..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        showZero
        rightContent={
          <Button
            id="new-auth-button"
            text="New Auth"
            onClick={() => showPreAuthPopup()}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.actionButton]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="pre-auths"
        classNames={[
          'medical-table-new',
          filteredAuths?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No pre auths"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'startDate',
          label: 'Start Date',
          width: '14%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredAuths}
        selectedTab={selectedTab}
      />
    </>
  );
};

PreAuths.propTypes = {
  patientId: PropTypes.number,
  actionsAllowed: PropTypes.bool,
};

export default PreAuths;
