// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPlanPopup-module__wrapper___YAcyZ {
  padding: 25px 30px;
  display: grid;
  column-gap: 30px;
  row-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}

.AddPlanPopup-module__buttonsContainer___e7q7y {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/AddPlanPopup/AddPlanPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,qCAAA;AACF;;AAEA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AACF","sourcesContent":[".wrapper {\n  padding: 25px 30px;\n  display: grid;\n  column-gap: 30px;\n  row-gap: 25px;\n  grid-template-columns: repeat(3, 1fr);\n}\n\n.buttonsContainer {\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n  justify-content: end;\n  padding: 30px;\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddPlanPopup-module__wrapper___YAcyZ`,
	"buttonsContainer": `AddPlanPopup-module__buttonsContainer___e7q7y`
};
module.exports = ___CSS_LOADER_EXPORT___;
