import { useEffect, useState } from 'react';

import { getCurrentUserId } from 'common-src/features/auth';
import { apiRequest } from 'common-src/features/rest';

import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';

const QUICK_SETTINGS_POLLING_FREQUENCY = 20 * 60 * 1000;

const useQuickSettings = (hasPermission) => {
  const [isRequesting, setIsRequesting] = useState(true);
  const [settings, setSettings] = useState({ availableForAlerts: false });

  const currentUserId = useCustomSelector((state) => getCurrentUserId(state));

  const fetchSettings = () => {
    if (!hasPermission) return;

    const requests = [
      apiRequest({
        endpoint: `clients/${currentUserId}/awscSettings`,
      }),
    ];

    Promise.all(requests)
      .then((responses) => {
        const awscSettingsPromise = responses[0]?.json();
        return { awscSettingsPromise };
      })
      .then((data) => {
        const { awscSettingsPromise } = data;
        return Promise.all([awscSettingsPromise]);
      })
      .then(([awscSettingsData]) => {
        setIsRequesting(false);
        setSettings((prev) => ({
          ...prev,
          availableForAlerts: awscSettingsData.availableForAlerts,
        }));
      });
  };

  useInterval(() => fetchSettings(), QUICK_SETTINGS_POLLING_FREQUENCY);

  useEffect(() => {
    fetchSettings();
  }, []);

  return { isRequesting, currentUserId, settings, setSettings };
};

export default useQuickSettings;
