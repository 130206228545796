import PropTypes from 'prop-types';
import React from 'react';

import Client from 'common-src/models/Client';

import styles from './CreatorItem.module.scss';

const CreatorItem = ({ creator, creatorName, isActive }) => (
  <div
    style={{ opacity: isActive ? 1 : 0.5 }}
    className={[styles.container, 'gap-2', 'flex-column'].join(' ')}
  >
    <span className={[styles.title, 'font-w-500', 'font-s-14'].join(' ')}>
      {creatorName || 'System user'}
    </span>
    <span className={[styles.subtitle, 'font-w-500', 'font-s-12'].join(' ')}>
      {creator?.role || 'System'}
    </span>
  </div>
);

CreatorItem.propTypes = {
  creator: PropTypes.exact(Client.schema),
  creatorName: PropTypes.string,
  isActive: PropTypes.bool,
};

export default CreatorItem;
