// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphTab-module__chart___m__Bs {
  width: 100%;
  height: 380px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/popups/TrendedResultsPopup/tabs/GraphTab/GraphTab.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;AACF","sourcesContent":[".chart {\n  width: 100%;\n  height: 380px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `GraphTab-module__chart___m__Bs`
};
module.exports = ___CSS_LOADER_EXPORT___;
