import useAlert from './useAlert';

const useOpenWindow = () => {
  const { showAlert, AlertType } = useAlert();

  const openWindow = ({ url, target = '', features = '' }) => {
    const newWindow = window.open(url, target, features);
    if (!newWindow) {
      showAlert(
        AlertType.Warning,
        '',
        'The window was blocked by the browser. Please enable popups for this site.',
      );
    }
  };

  return { openWindow };
};

export default useOpenWindow;
