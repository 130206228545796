// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultilineTextItem-module__text___O7xP1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}`, "",{"version":3,"sources":["webpack://./src/components/table/MultilineTextItem/MultilineTextItem.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,oBAAA;EACA,4BAAA;AACF","sourcesContent":[".text {\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `MultilineTextItem-module__text___O7xP1`
};
module.exports = ___CSS_LOADER_EXPORT___;
