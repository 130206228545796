/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { apiRequest, getRestErrorMessage, HttpMethods } from 'common-src/features/rest';
import { getInsulinUse, getPrimaryDiagnosis } from 'common-src/models/PatientMedicalProblem';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './CGMOrderPopup.module.scss';
import { BooleanOptions, CGMModelOptions, DiagnosisOptions } from './constants';
import FormRow from './FormRow';

const CGMOrderPopup = ({ open, onClose, patientId }) => {
  const [data, setData] = useState({
    cgmModel: CGMModelOptions.DexcomG7.value,
    diagnosis: null,
    isInsulinTreated: BooleanOptions.YES.value,
    hasProblematicHypoglycemia: BooleanOptions.NO.value,
  });

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const primaryDiagnosis = useCustomSelector((state) => getPrimaryDiagnosis(state, patientId));
  const insulinUse = useCustomSelector((state) => getInsulinUse(state, patientId));

  const { renderButtons } = usePopup();

  useEffect(() => {
    if (!patient?.cgmModel) return;

    setData((data) => ({ ...data, cgmModel: patient.cgmModel }));
  }, [patient?.cgmModel]);

  useEffect(() => {
    if (!primaryDiagnosis) return;

    setData((data) => ({ ...data, diagnosis: primaryDiagnosis?.icd10Code }));
  }, [primaryDiagnosis]);

  useEffect(() => {
    setData((data) => ({
      ...data,
      isInsulinTreated: insulinUse ? BooleanOptions.YES.value : BooleanOptions.NO.value,
    }));
  }, [insulinUse]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    apiRequest({
      endpoint: 'jobRequests/cgmPrescription',
      method: HttpMethods.Post,
      body: {
        patient_id: patientId,
        cgm_model: data.cgmModel,
        diagnosis: data.diagnosis,
        is_insulin_treated: data.isInsulinTreated,
        has_history_of_problematic_hypoglycemia: data.hasProblematicHypoglycemia,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const errMsg = getRestErrorMessage(data);
        if (errMsg) {
          errorCallback(errMsg);
          return;
        }

        successCallback('CGM prescription ordered');
        onClose(true);
      });
  };

  return (
    <BasePopup id="cgm-order" open={open} onClose={onClose} title="CGM Order - Medicare & Med Adv">
      <div className="m-l-30">
        <FormRow
          title="Device to Order"
          options={CGMModelOptions}
          value={data.cgmModel}
          onChangeHandler={setData}
          field="cgmModel"
        />
        <FormRow
          title="Confirm Diagnosis"
          options={DiagnosisOptions}
          value={data.diagnosis}
          placeholder="Select diagnosis"
          onChangeHandler={setData}
          field="diagnosis"
        />
        <FormRow
          title="Insulin Treated"
          options={BooleanOptions}
          value={data.isInsulinTreated}
          onChangeHandler={setData}
          field="isInsulinTreated"
        />
        <FormRow
          title="History of problematic hypoglycemia"
          options={BooleanOptions}
          value={data.hasProblematicHypoglycemia}
          onChangeHandler={setData}
          field="hasProblematicHypoglycemia"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        submitButtonText: 'Order and Close',
        isSubmitEnabled: !Object.values(data).some((value) => value === null),
      })}
    </BasePopup>
  );
};

CGMOrderPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  patientId: PropTypes.number,
};

export default CGMOrderPopup;
