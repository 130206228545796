import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Checkbox, TextArea } from 'common-src/components/base';

import commonStyles from 'src/pages/DocumentationIndex/commonStyles.module.scss';

import styles from './CGMAlertsModule.module.scss';
import { CgmAlerts, initialCgmAlertsState, riseRateOptions } from './constants';

const CGMAlertsModule = ({ cgmAlerts, setCgmAlerts, setHasDetectedChanges, classNames = [] }) => {
  useEffect(() => {
    if (_.isEmpty(cgmAlerts)) {
      setCgmAlerts(initialCgmAlertsState);
    }
  }, [cgmAlerts]);

  const onChangeHandler = (key, value) => {
    setCgmAlerts((prev) => ({ ...prev, [key]: value }));
    setHasDetectedChanges(new Date());
  };

  const renderConditionQuestions = (alert) => {
    switch (alert) {
      case CgmAlerts.UrgentLowSoon:
        return cgmAlerts.alerts?.includes(CgmAlerts.UrgentLowSoon) ? (
          <TextArea
            key="urgent-low-soon-set-at"
            id="urgent-low-soon-set-at"
            classNames={[styles.input]}
            inputClassNames={[commonStyles.inputText]}
            label="Set At:"
            value={cgmAlerts.urgentLowSoonSetAt}
            fixedRows={1}
            onFinishEditing={(value) => onChangeHandler('urgentLowSoonSetAt', value)}
            debounced
          />
        ) : null;
      case CgmAlerts.LowGlucoseAlert:
        return cgmAlerts.alerts?.includes(CgmAlerts.LowGlucoseAlert) ? (
          <TextArea
            key="low-glucose-alert-set-at"
            id="low-glucose-alert-set-at"
            classNames={[styles.input]}
            inputClassNames={[commonStyles.inputText]}
            label="Set At:"
            value={cgmAlerts.lowGlucoseAlertSetAt}
            fixedRows={1}
            onFinishEditing={(value) => onChangeHandler('lowGlucoseAlertSetAt', value)}
            debounced
          />
        ) : null;
      case CgmAlerts.HighGlucoseAlert:
        return cgmAlerts.alerts?.includes(CgmAlerts.HighGlucoseAlert) ? (
          <TextArea
            key="high-glucose-alert-set-at"
            id="high-glucose-alert-set-at"
            classNames={[styles.input]}
            inputClassNames={[commonStyles.inputText]}
            label="Set At:"
            value={cgmAlerts.highGlucoseAlertSetAt}
            fixedRows={1}
            onFinishEditing={(value) => onChangeHandler('highGlucoseAlertSetAt', value)}
            debounced
          />
        ) : null;
      case CgmAlerts.RiseRate:
        return cgmAlerts.alerts?.includes(CgmAlerts.RiseRate) ? (
          <div key="rise-rate" className="m-l-24">
            {riseRateOptions.map((op) => (
              <Checkbox
                key={op}
                id={`rise-rate-${op}`}
                classNames={[commonStyles.checkbox, 'm-b-16']}
                label={op}
                checked={cgmAlerts.riseRate?.includes(op)}
                onChange={() =>
                  onChangeHandler(
                    'riseRate',
                    cgmAlerts.riseRate.includes(op)
                      ? cgmAlerts.riseRate.filter((value) => value !== op)
                      : [...cgmAlerts.riseRate, op],
                  )
                }
              />
            ))}
          </div>
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div className={[styles.container, 'flex-column', 'gap-14', ...classNames].join(' ')}>
      <span className={['font-w-600', 'font-s-16'].join(' ')}>Current Alerts/Alarms</span>
      <span className={['font-w-500', 'font-s-14'].join(' ')}>Select all that apply:</span>
      <div>
        {Object.values(CgmAlerts).map((alert) => (
          <div key={alert}>
            <Checkbox
              id={`cgm-alert-${alert}`}
              classNames={[commonStyles.checkbox, 'm-b-16']}
              label={alert}
              checked={cgmAlerts.alerts?.includes(alert)}
              onChange={() =>
                onChangeHandler(
                  'alerts',
                  cgmAlerts.alerts.includes(alert)
                    ? cgmAlerts.alerts.filter((value) => value !== alert)
                    : [...cgmAlerts.alerts, alert],
                )
              }
            />
            {renderConditionQuestions(alert)}
          </div>
        ))}
      </div>
    </div>
  );
};

CGMAlertsModule.propTypes = {
  cgmAlerts: PropTypes.object,
  setCgmAlerts: PropTypes.func,
  setHasDetectedChanges: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default CGMAlertsModule;
